export const variousConditions = [
  { name: 'Anemia' },
  { name: 'Arthritis - Rheumatoid' },
  { name: 'Arthritis - Osteoarthritis' },
  { name: 'Diabetes' },
  { name: 'Pre-Diabetes' },
  { name: 'Lupus' },
  { name: "Raynaud's" },
  { name: "Sjögen's" },
  { name: 'Lymphodema' },
  { name: 'Dyslipidemia' },
  { name: 'Cancer' },
  { name: 'Anxiety' },
  { name: 'Depression' },
  { name: 'History of blood clots' },
  { name: 'Stroke' },
  { name: 'TIA Transient Ischemic Attack' },
  { name: 'Kidney Disease' },
  { name: 'Liver Disease' },
]

export const neurologicalConditions = [
  { name: "Dementia - Alzheimer's" },
  { name: 'Dementia - Frontotemporal (FTD)' },
  { name: 'Dementia - Lewy Body' },
  { name: 'Dementia - Other' },
  { name: 'Multiple Scleriols' },
  { name: "Parkinson's" },
  { name: 'Mysathenia gravis' },
  { name: 'Peripheral neuropathy' },
  { name: 'Other neurological condition' },
]

export const heartConditions = [
  { name: 'Atherosclerosis' },
  { name: 'Venous disease' },
  { name: 'Atrial fibrilation (Afib)' },
  { name: 'High blood pressure' },
  { name: 'Irregular heart beat' },
  { name: 'Myocarditis, Cardiomyopathy' },
  { name: 'Mysathenia gravis' },
  { name: 'Peripheral neuropathy' },
  { name: 'Congestive heart failure' },
]
export const gastroConditions = [
  { name: 'Celiac disease' },
  { name: "Crohn's disease" },
  { name: 'IBS Irritable Bowel Syndrome' },
  { name: 'Ulcerative Colitis' },
  { name: 'Gastritis, Esophagitis, GERD' },
  { name: 'Chronic Constipation' },
  { name: 'Chronic Diarrhea' },
  { name: 'Diverticulitis' },
  { name: 'Intestinal Dysbiosis' },
  { name: 'SIBO' },
  { name: 'Candida' },
  { name: 'C difficile' },
  { name: 'Other gastrointestinal issues' },
]
export const endocrineConditions = [
  { name: 'Low thyroid' },
  { name: 'Hyperthyroid' },
  { name: 'Adrenal fatigue' },
  { name: 'PCOS' },
  { name: 'Other endocrine' },
]
export const respiratoryConditions = [
  { name: 'Asthma' },
  { name: 'Bronchitis, Emphysema, COPD' },
  { name: 'Chronic sinusitis' },
  { name: 'Other respiratory' },
]
export const viralConditions = [
  { name: 'Covid' },
  { name: 'EBV Epstein Barr' },
  { name: 'Flu (severe)' },
  { name: 'Herpes HSV1' },
  { name: 'Herpes HSV2' },
  { name: 'Herpes HSV6' },
  { name: 'HIV' },
  { name: 'Lyme (Babecia, bartonella, etc.' },
  { name: 'Meningitis (any type)' },
  { name: 'Pneumonia (any type)' },
  { name: 'Other' },
]
export const fungalConditions = [
  { name: 'Candida' },
  { name: 'CIRS Chronic Inflammation Response Syndrome' },
]
export const parasitesConditions = [
  { name: 'Have you ever been diagnosed with parasites?' },
]
