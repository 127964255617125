import React from 'react'
import { FormControl, FormLabel, Input, HStack } from '@chakra-ui/react'
import { keepNumberInRange } from '../utils/dataValidation'

interface ValidatedInputProps {
  label: string
  value: number | null
  min: number
  max: number
  isRequired?: boolean
  onChange: (value: number | null) => void
  width?: string | number
  inputWidth?: string | number
  inline?: boolean
  pl?: number
  isDisabled?: boolean
}

const ValidatedInput: React.FC<ValidatedInputProps> = ({
  label,
  value = null,
  min,
  max,
  isRequired = true,
  onChange,
  width = '100%',
  inputWidth = '50%',
  inline = true,
  pl = 0,
  isDisabled = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (inputValue === '') {
      onChange(null)
    } else {
      const validatedValue = keepNumberInRange(inputValue, min, max)
      onChange(validatedValue)
    }
  }

  return (
    <FormControl isRequired={isRequired} width={width} isDisabled={isDisabled}>
      {inline ? (
        <HStack>
          <FormLabel flex="1" pl={pl}>
            {label}
          </FormLabel>
          <Input
            type="number"
            value={value ?? ''}
            onChange={handleChange}
            width={inputWidth}
          />
        </HStack>
      ) : (
        <>
          <FormLabel>{label}</FormLabel>
          <Input
            type="number"
            value={value ?? ''}
            onChange={handleChange}
            width={inputWidth}
          />
        </>
      )}
    </FormControl>
  )
}

export default ValidatedInput
