import { Text, Tr, Td, Checkbox, Input, Select } from '@chakra-ui/react'
import { useState, useMemo } from 'react'

export default function MedicalConditionRow({
  condition,
  age,
}: {
  condition: string
  age: number | undefined
}) {
  const [isChecked, setIsChecked] = useState(false)
  const [frequency, setFrequency] = useState('')
  const [ageDiagnosed, setAgeDiagnosed] = useState<number | undefined>()
  const [ageEnded, setAgeEnded] = useState<number | undefined>()

  const estimatedYears = useMemo(() => {
    if (frequency !== 'constant' || !ageDiagnosed) return undefined
    if (ageEnded !== undefined) {
      return ageEnded - ageDiagnosed
    }
    if (age !== undefined) {
      return age - ageDiagnosed
    }
    return undefined
  }, [frequency, ageDiagnosed, ageEnded, age])

  return (
    <Tr>
      <Td p={2}>
        <Text fontSize="sm">{condition}</Text>
      </Td>
      <Td textAlign="left" p={2}>
        <Checkbox
          // size="sm"
          isChecked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          ml={4}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          placeholder="Age Diagnosed"
          isDisabled={!isChecked}
          isRequired={isChecked}
          value={ageDiagnosed !== undefined ? ageDiagnosed : ''}
          onChange={(e) => setAgeDiagnosed(Number(e.target.value))}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          placeholder="Age Ended"
          isDisabled={!isChecked}
          value={ageEnded !== undefined ? ageEnded : ''}
          onChange={(e) => setAgeEnded(Number(e.target.value))}
        />
      </Td>
      <Td p={2}>
        <Select
          size="sm"
          placeholder="Frequency"
          isDisabled={!isChecked}
          isRequired={isChecked}
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
        >
          <option value="intermittent">Intermittent</option>
          <option value="constant">Constant</option>
        </Select>
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          placeholder="Estimated Years"
          isDisabled={!isChecked || frequency === 'constant'}
          isRequired={isChecked && frequency !== 'constant'}
          value={frequency === 'constant' ? estimatedYears ?? '' : ''}
        />
      </Td>
    </Tr>
  )
}
