import { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Input,
} from '@chakra-ui/react'

const surgeries = [
  'Back/Neck surgery',
  'Limb/joint surgery - upper',
  'Limb/joint surgery - lower',
  'Appendix removed',
  'Gall bladder removed',
  'Spleen removed',
  'Tonsils removed',
  'Adenoids removed',
  'Eye surgery',
  'Cancer related surgery',
  'Heart surgery stent/bypass/pacemaker/etc.',
  'Some Lymph nodes removed',
  'Organ replacement',
  'Other',
]

type SurgeryData = {
  name: string
  hadSurgery: boolean
  age1: string
  age2: string
  age3: string
  age4: string
}

export default function SurgeryHistory() {
  const [surgeryData, setSurgeryData] = useState<SurgeryData[]>(
    surgeries.map((surgery) => ({
      name: surgery,
      hadSurgery: false,
      age1: '',
      age2: '',
      age3: '',
      age4: '',
    }))
  )

  function handleCheckboxChange(index: number) {
    const newSurgeryData = [...surgeryData]
    newSurgeryData[index].hadSurgery = !newSurgeryData[index].hadSurgery
    setSurgeryData(newSurgeryData)
  }

  function handleInputChange(
    index: number,
    field: 'age1' | 'age2' | 'age3' | 'age4',
    value: string
  ) {
    const newSurgeryData = [...surgeryData]
    // TypeScript now knows these fields will only contain strings
    newSurgeryData[index][field] = value
    setSurgeryData(newSurgeryData)
  }

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Surgery</Th>
          <Th>Had Surgery</Th>
          <Th>Age of 1st Surgery</Th>
          <Th>Age of 2nd Surgery</Th>
          <Th>Age of 3rd Surgery</Th>
          <Th>Age of 4th Surgery</Th>
        </Tr>
      </Thead>
      <Tbody>
        {surgeryData.map((surgery, index) => (
          <Tr key={surgery.name}>
            <Td>{surgery.name}</Td>
            <Td>
              <Checkbox
                isChecked={surgery.hadSurgery}
                onChange={() => handleCheckboxChange(index)}
              />
            </Td>
            <Td>
              <Input
                value={surgery.age1}
                onChange={(e) =>
                  handleInputChange(index, 'age1', e.target.value)
                }
                isDisabled={!surgery.hadSurgery}
                placeholder="Age"
              />
            </Td>
            <Td>
              <Input
                value={surgery.age2}
                onChange={(e) =>
                  handleInputChange(index, 'age2', e.target.value)
                }
                isDisabled={!surgery.hadSurgery}
                placeholder="Age"
              />
            </Td>
            <Td>
              <Input
                value={surgery.age3}
                onChange={(e) =>
                  handleInputChange(index, 'age3', e.target.value)
                }
                isDisabled={!surgery.hadSurgery}
                placeholder="Age"
              />
            </Td>
            <Td>
              <Input
                value={surgery.age4}
                onChange={(e) =>
                  handleInputChange(index, 'age4', e.target.value)
                }
                isDisabled={!surgery.hadSurgery}
                placeholder="Age"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
