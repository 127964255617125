import { useCallback } from 'react'
import { AdditionalDetails } from '../../types/FirestoreUser'

const useUpdateAdditionalDetails = (
  setAdditionalDetails: React.Dispatch<
    React.SetStateAction<Partial<AdditionalDetails>>
  >
) => {
  const update = useCallback(
    (partial: Partial<AdditionalDetails>) => {
      setAdditionalDetails((prevDetails) => ({
        ...prevDetails,
        ...partial,
        alsOnset: partial.alsOnset ?? prevDetails.alsOnset ?? '',
        contributingFactors: {
          ...prevDetails.contributingFactors,
          ...partial.contributingFactors,
        },
        symptoms: partial.symptoms ?? prevDetails.symptoms ?? [],
      }))
    },
    [setAdditionalDetails]
  )

  return update
}

export default useUpdateAdditionalDetails
