import { RouteObject } from "react-router-dom";

import Admin from "../pages/Admin";
import AdminBloodWork from "../pages/AdminBloodWork";
import AdminCompletion from "../pages/AdminCompletion";
import AdminConfig from "../pages/AdminConfig";
import AdminDoctors from "../pages/AdminDoctors";
import { AdminEditProfile } from "../pages/EditProfile";
import AdminListPage from "../pages/AdminListPage";
import AdminPrint from "../pages/AdminPrint";
import AdminStages from "../pages/AdminStages";
import AdminUserPage from "../pages/AdminUserPage";
import ContentManagement from "../pages/ContentManagement";
import CreateBloodWorkLab from "../pages/CreateBloodWorkLab";
import Users from "../pages/Users";

const adminRoutes: RouteObject[] = [
  { path: "/admin", element: <Admin /> },
  { path: "/admin/doctors", element: <AdminDoctors /> },
  { path: "/admin/config", element: <AdminConfig /> },
  {
    path: "/admin/brands",
    element: <AdminListPage title="Brands" docId="brands" />,
  },
  {
    path: "/admin/stress-factors",
    element: <AdminListPage title="Stress Factors" docId="stressFactors" />,
  },
  {
    path: "/admin/supplements",
    element: <AdminListPage title="Supplements" docId="supplements" />,
  },
  {
    path: "/admin/diets",
    element: <AdminListPage title="Diets" docId="diets" />,
  },
  {
    path: "/admin/protocols",
    element: <AdminListPage title="Protocols" docId="protocols" />,
  },
  {
    path: "/admin/mental-strategies",
    element: <AdminListPage title="Mind Strategies" docId="mentalStrategies" />,
  },
  {
    path: "/admin/physical-strategies",
    element: (
      <AdminListPage title="Physical Strategies" docId="physicalStrategies" />
    ),
  },
  {
    path: "/admin/non-sup-medications",
    element: (
      <AdminListPage
        title="Non-Supplement Medication"
        docId="nonSupplementMedications"
      />
    ),
  },
  {
    path: "/admin/healing-als-studies",
    element: <AdminListPage title="HealingALS Studies" docId="studies" />,
  },
  {
    path: "/admin/external-studies",
    element: (
      <AdminListPage title="External Studies" docId="external-studies" />
    ),
  },
  {
    path: "/admin/measurement-units",
    element: (
      <AdminListPage title="Measurement Units" docId="measurement-units" />
    ),
  },
  { path: "/admin/users/:id", element: <AdminUserPage /> },
  { path: "/admin/users/:id/edit-profile", element: <AdminEditProfile /> },
  { path: "/admin/completion", element: <AdminCompletion /> },
  { path: "/admin/users", element: <Users /> },
  { path: "/admin/stages", element: <AdminStages /> },
  { path: "/admin/labs", element: <AdminBloodWork /> },
  { path: "/admin/labs/create", element: <CreateBloodWorkLab /> },
  { path: "/admin/labs/:editingId/edit", element: <CreateBloodWorkLab /> },
  { path: "/admin/users/:uid/print", element: <AdminPrint /> },
  { path: "/admin/content-management", element: <ContentManagement /> },
];

export default adminRoutes;
