import { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Input,
} from '@chakra-ui/react'

const injuries = [
  'TBI Traumatic Brain Injury - Mild (Concussion)',
  'TBI Traumatic Brain Injury - Severe',
  'Spine injury - neck',
  'Spine injury - upper and/or mid back',
  'Spine injury - lower back',
  'Shoulder',
  'Arm/elbow/wrist/fingers',
  'Hip/femur/knee/lower leg/ankle/foot',
  'Chest/Abdomen',
]

type InjuryData = {
  name: string
  hadCondition: boolean
  age1: string
  age2: string
  age3: string
  age4: string
}

export default function InjuryHistory() {
  const [injuryData, setInjuryData] = useState<InjuryData[]>(
    injuries.map((injury) => ({
      name: injury,
      hadCondition: false,
      age1: '',
      age2: '',
      age3: '',
      age4: '',
    }))
  )

  function handleCheckboxChange(index: number) {
    const newInjuryData = [...injuryData]
    newInjuryData[index].hadCondition = !newInjuryData[index].hadCondition
    setInjuryData(newInjuryData)
  }

  function handleInputChange(
    index: number,
    field: 'age1' | 'age2' | 'age3' | 'age4',
    value: string
  ) {
    const newInjuryData = [...injuryData]
    newInjuryData[index][field] = value
    setInjuryData(newInjuryData)
  }

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Injury</Th>
          <Th>Had Injury</Th>
          <Th>Age 1st injury to this area</Th>
          <Th>Age 2nd injury to this area</Th>
          <Th>Age 3rd injury to this area</Th>
          <Th>Age 4th injury to this area</Th>
        </Tr>
      </Thead>
      <Tbody>
        {injuryData.map((injury, index) => (
          <Tr key={injury.name}>
            <Td>{injury.name}</Td>
            <Td textAlign="left">
              <Checkbox
                isChecked={injury.hadCondition}
                onChange={() => handleCheckboxChange(index)}
              />
            </Td>
            <Td>
              <Input
                value={injury.age1}
                onChange={(e) =>
                  handleInputChange(index, 'age1', e.target.value)
                }
                isDisabled={!injury.hadCondition}
                placeholder="Age"
                type="number"
              />
            </Td>
            <Td>
              <Input
                value={injury.age2}
                onChange={(e) =>
                  handleInputChange(index, 'age2', e.target.value)
                }
                isDisabled={!injury.hadCondition}
                placeholder="Age"
                type="number"
              />
            </Td>
            <Td>
              <Input
                value={injury.age3}
                onChange={(e) =>
                  handleInputChange(index, 'age3', e.target.value)
                }
                isDisabled={!injury.hadCondition}
                placeholder="Age"
                type="number"
              />
            </Td>
            <Td>
              <Input
                value={injury.age4}
                onChange={(e) =>
                  handleInputChange(index, 'age4', e.target.value)
                }
                isDisabled={!injury.hadCondition}
                placeholder="Age"
                type="number"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
