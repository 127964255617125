import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

interface ClinicalTrialFormProps {
  study: string
  setStudy: (value: string) => void
  startDate: string
  setStartDate: (value: string) => void
  endDate: string
  setEndDate: (value: string) => void
  group: string
  setGroup: (value: string) => void
  extraInfoRequired: boolean
}

export default function ClinicalTrialForm({
  study,
  setStudy,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  group,
  setGroup,
  extraInfoRequired,
}: ClinicalTrialFormProps) {
  const [externalStudies, setExternalStudies] = useState<
    Record<string, string>
  >({})

  useEffect(() => {
    const fetchExternalStudies = async () => {
      const ref = doc(getFirestore(), 'config', 'external-studies')
      const snap = await getDoc(ref)
      if (snap.exists()) {
        setExternalStudies(snap.data().value)
      }
    }
    fetchExternalStudies()
  }, [])

  const sortedOptions = useMemo(() => {
    return Object.entries(externalStudies).sort(([, valueA], [, valueB]) => {
      return valueA.localeCompare(valueB)
    })
  }, [externalStudies])

  return (
    <>
      <FormControl isRequired={extraInfoRequired}>
        <FormLabel>Study</FormLabel>
        <Select
          id="study"
          placeholder="Select study"
          value={study}
          onChange={(e) => setStudy(e.target.value)}
        >
          {sortedOptions.map(([key, optionValue]) => {
            const displayValue = optionValue.replace(/^\d+\.\s*/, '')
            return (
              <option key={key} value={optionValue}>
                {displayValue}
              </option>
            )
          })}
        </Select>
      </FormControl>
      <FormControl mt={4} isRequired={extraInfoRequired}>
        <FormLabel>Start Date</FormLabel>
        <Input
          id="startDate"
          placeholder="YYYY-MM-DD"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>End Date (leave blank if current)</FormLabel>
        <Input
          id="endDate"
          placeholder="YYYY-MM-DD"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </FormControl>
      <FormControl mt={4} isRequired={extraInfoRequired}>
        <FormLabel>If the study has ended, which group were you in?</FormLabel>
        <Select
          id="group"
          placeholder="Select group"
          value={group}
          onChange={(e) => setGroup(e.target.value)}
        >
          <option value="Interventional">Interventional Treatment</option>
          <option value="Placebo">Placebo</option>
        </Select>
      </FormControl>
    </>
  )
}
