// PurpleBubbleContent.tsx
import React from "react";
import { Center, Flex, Spinner } from "@chakra-ui/react";

export default () => {
  return (
    <Flex height={"100vh"} alignContent={"center"} justifyContent={"center"}>
      <Center>
        <Spinner
          thickness="6px"
          speed="0.8s"
          emptyColor="gray.200"
          color="var(--chakra-colors-purple-500)"
          size="xl"
        />
      </Center>
    </Flex>
  );
};
