import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Heading, Select, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLabs } from "../data/labsCollection";

export default () => {
  const [labId, setLabId] = useState<string>();
  const [labs] = useLabs({});
  return (
    <Stack spacing={6}>
      <Heading>Lab Tests</Heading>
      <Box>
        <Link to="/admin/labs/create">
          <Button variant="outline" leftIcon={<AddIcon />}>
            Add New Lab
          </Button>
        </Link>
      </Box>
      <Divider />
      <Stack spacing={2}>
        <Heading size="sm">Edit Existing Lab</Heading>
        <Select
          maxW="md"
          placeholder="Choose Lab to Edit"
          value={labId}
          onChange={(e) => {
            setLabId(e.target.value);
          }}
        >
          {labs &&
            labs.map((doc) => {
              const lab = doc.data();
              return (
                <option value={doc.id} key={doc.id}>
                  {lab.title}
                  {lab.archived ? " (Archived)" : ""}
                </option>
              );
            })}
        </Select>
        <Box>
          <Link to={`/admin/labs/${labId}/edit`}>
            <Button
              colorScheme="gray"
              leftIcon={<EditIcon />}
              isDisabled={!labId}
            >
              Edit
            </Button>
          </Link>
        </Box>
      </Stack>
    </Stack>
  );
};
