import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  Grid,
  GridItem,
  Input,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import usePurpleBubblesText from '../../../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../../../components/PurpleBubbleContent'

interface PrescriptionModalProps {
  isOpen: boolean
  onClose: () => void
  drug: string
  setDrug: (drug: string) => void
  category: string
  setCategory: (category: string) => void
  ageStarted: number | null
  setAgeStarted: (age: number | null) => void
  ageStopped: number | null
  setAgeStopped: (age: number | null) => void
  usageType: 'Intermittent' | 'Constant'
  setUsageType: (type: 'Intermittent' | 'Constant') => void
  totalYears: number | null
  setTotalYears: (years: number | null) => void
  prescriptionList: Record<string, string>
  handleSave: () => void
}

export default function PrescriptionModal({
  isOpen,
  onClose,
  drug,
  setDrug,
  category,
  setCategory,
  ageStarted,
  setAgeStarted,
  ageStopped,
  setAgeStopped,
  usageType,
  setUsageType,
  totalYears,
  setTotalYears,
  prescriptionList,
  handleSave,
}: PrescriptionModalProps) {
  const purpleBubbleContent = usePurpleBubblesText('Profile-Prescription')
  const toast = useToast()

  useEffect(() => {
    if (
      usageType === 'Constant' &&
      ageStarted !== null &&
      ageStopped !== null
    ) {
      setTotalYears(ageStopped - ageStarted)
    }
  }, [ageStarted, ageStopped, usageType, setTotalYears])

  const handleClose = () => {
    setDrug('')
    setCategory('')
    setAgeStarted(null)
    setAgeStopped(null)
    setUsageType('Intermittent')
    setTotalYears(null)
    onClose()
  }

  const handleSaveAndClose = () => {
    if (!drug && !category) {
      toast({
        title: 'Validation Error',
        description: 'Please select either a drug or a category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (ageStarted === null || ageStopped === null || totalYears === null) {
      toast({
        title: 'Validation Error',
        description: 'Please fill in all required fields.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (isNaN(ageStarted) || isNaN(ageStopped) || isNaN(totalYears)) {
      toast({
        title: 'Validation Error',
        description: 'Age values must be numbers.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (ageStopped < ageStarted) {
      toast({
        title: 'Validation Error',
        description:
          'Age at which stopped cannot be before age at which first took medication.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    handleSave()
    handleClose()
  }

  const handleUsageTypeChange = (value: 'Intermittent' | 'Constant') => {
    setUsageType(value)
    if (value === 'Constant' && ageStarted !== null && ageStopped !== null) {
      setTotalYears(ageStopped - ageStarted)
    }
  }

  const handleAgeChange = (
    setter: (age: number | null) => void,
    value: string
  ) => {
    const numericValue = value === '' ? null : parseInt(value, 10)
    setter(numericValue)
  }

  const sortedPrescriptionList = Object.entries(prescriptionList).sort((a, b) =>
    a[1].localeCompare(b[1])
  )

  const categories = [
    'Cholesterol lowering drugs',
    'Anti-inflamatories',
    'Immuno-suppressants',
    'Antibiotics',
    'Anti-anxiety',
    'Anti-depressants',
    'Oral contraceptives',
    'Hormone replacement therapy',
    'Anti-hypertensive drugs',
    'Anti-diabetics',
  ].sort((a, b) => a.localeCompare(b))

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Prescription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {purpleBubbleContent?.text && (
            <PurpleBubbleContent text={purpleBubbleContent.text} />
          )}
          <Text mb={4}>
            Select the drug name or category if you cannot recall its name.
          </Text>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>Drug</FormLabel>
                <Select
                  placeholder="Select drug"
                  value={drug}
                  onChange={(e) => setDrug(e.target.value)}
                >
                  {sortedPrescriptionList.map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
          <FormControl mt={4} isRequired>
            <FormLabel>Age at which you first took this medication</FormLabel>
            <Input
              type="number"
              placeholder="Age Started"
              value={ageStarted !== null ? ageStarted.toString() : ''}
              onChange={(e) => handleAgeChange(setAgeStarted, e.target.value)}
              min={0}
              max={100}
            />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>
              Age at which you stopped this medication (or current)
            </FormLabel>
            <Input
              type="number"
              placeholder="Age Stopped"
              value={ageStopped !== null ? ageStopped.toString() : ''}
              onChange={(e) => handleAgeChange(setAgeStopped, e.target.value)}
              min={0}
              max={100}
            />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Intermittent or Constant</FormLabel>
            <Select
              placeholder="Select usage type"
              value={usageType}
              onChange={(e) =>
                handleUsageTypeChange(
                  e.target.value as 'Intermittent' | 'Constant'
                )
              }
            >
              <option value="Intermittent">Intermittent</option>
              <option value="Constant">Constant</option>
            </Select>
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Estimated Total Number of years taking drug</FormLabel>
            <Input
              type="number"
              placeholder="Total Years"
              value={totalYears !== null ? totalYears.toString() : ''}
              onChange={(e) =>
                setTotalYears(
                  e.target.value === '' ? null : parseInt(e.target.value, 10)
                )
              }
              min={0}
              max={100}
              isDisabled={usageType === 'Constant'}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveAndClose}>
            Save
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
