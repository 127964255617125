export type CalculatedField = {
  title: string;
  dividend: string;
  divisor: string;
};
export const calculatedFields: CalculatedField[] = [
  {
    title: "BUN/Creatinine Ratio",
    dividend: "BUN",
    divisor: "Creatinine",
  },
  {
    title: "Neutrophil/Lymphocyte Ratio",
    dividend: "Neutrophils",
    divisor: "Lymphocytes",
  },
  {
    title: "Triglycerides/HDL Ratio",
    dividend: "Triglycerides",
    divisor: "HDL High Density Lipoprotein",
  },
];
