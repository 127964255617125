import { Text, SimpleGrid, Tooltip } from '@chakra-ui/react'
import AlsFactorSelect from './AlsFactorSelect'
import { QuestionIcon } from '@chakra-ui/icons'

interface Props {
  contributingFactors: Partial<{
    primary: string
    secondary: string
    tertiary: string
    quaternary: string
    quinary: string
  }>
  update: (
    factors: Partial<{
      primary: string
      secondary: string
      tertiary: string
      quaternary: string
      quinary: string
    }>
  ) => void
  isRequired: boolean
}

const AlsFactorsQuestions = ({
  contributingFactors,
  update,
  isRequired,
}: Props) => {
  return (
    <>
      <Text fontSize="lg" fontWeight="bold" mt={4}>
        What do YOU think were the top 5 factors that happened BEFORE your FIRST
        symptom onset that may have been contributing factors to your ALS
        symptoms?{' '}
        <Tooltip label="If your factor is not listed send an email to info@healingals.org">
          <QuestionIcon color="gray.400" />
        </Tooltip>
      </Text>
      <SimpleGrid columns={2} spacing={4} mt={4}>
        <AlsFactorSelect
          value={contributingFactors.primary || ''}
          label="Primary Factor"
          onChange={(value) =>
            update({
              primary: value,
            })
          }
          isRequired={isRequired}
        />
        <AlsFactorSelect
          value={contributingFactors.secondary || ''}
          label="Secondary Factor"
          onChange={(value) =>
            update({
              secondary: value,
            })
          }
          isRequired={isRequired}
        />
        <AlsFactorSelect
          value={contributingFactors.tertiary || ''}
          label="Third Factor"
          onChange={(value) =>
            update({
              tertiary: value,
            })
          }
          isRequired={isRequired}
        />
        <AlsFactorSelect
          value={contributingFactors.quaternary || ''}
          label="Fourth Factor"
          onChange={(value) =>
            update({
              quaternary: value,
            })
          }
          isRequired={isRequired}
        />
        <AlsFactorSelect
          value={contributingFactors.quinary || ''}
          label="Fifth Factor"
          onChange={(value) =>
            update({
              quinary: value,
            })
          }
          isRequired={isRequired}
        />
      </SimpleGrid>
    </>
  )
}

export default AlsFactorsQuestions
