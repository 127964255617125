import { useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import ClinicalTrialsModal from './ClinicalTrialsModal'
import useSaveClinicalTrial from './useSaveClinicalTrial'
import FirestoreUser, { AdditionalDetails } from '../../../types/FirestoreUser'
import { v4 as uuidv4 } from 'uuid'
import useDeleteTrial from './useDeleteTrial'
import useStopTrial from './useStopTrial'
import DeleteAlertDialog from './DeleteAlertDialog'
import StopClinicalTrialModal from './StopClinicalTrialModal'
import ClinicalTrialsTable from './ClinicalTrialsTable'

interface Props {
  additionalDetails: AdditionalDetails
  setAdditionalDetails: React.Dispatch<
    React.SetStateAction<Partial<AdditionalDetails>>
  >
  update: (partial: Partial<AdditionalDetails>) => void
  firestoreUser: FirestoreUser
  isRequired: boolean
  uid: string
  stopTrial: ReturnType<typeof useStopTrial>
}

export default function ClinicalTrialsQuestions({
  additionalDetails,
  setAdditionalDetails,
  update,
  firestoreUser,
  isRequired,
  uid,
  stopTrial,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast() // Initialize useToast
  const [id, setId] = useState<string | undefined>(undefined)
  const [study, setStudy] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [group, setGroup] = useState('')
  const [futureTrialAgreement, setFutureTrialAgreement] = useState(
    additionalDetails.futureTrialAgreement || false
  )

  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    cancelRef,
    handleDeleteTrial,
    confirmDeleteTrial,
  } = useDeleteTrial(uid, additionalDetails, setAdditionalDetails)

  const {
    isOpen: isStopOpen,
    onClose: onStopClose,
    handleStopTrial,
    confirmStopTrial,
  } = stopTrial

  interface ClinicalTrial {
    id?: string
    study: string
    startDate: string
    endDate?: string
    group?: string
  }

  const { handleSave } = useSaveClinicalTrial(
    id,
    study,
    startDate,
    endDate,
    group,
    firestoreUser,
    update,
    onClose,
    uuidv4,
    additionalDetails,
    toast, // Pass toast to useSaveClinicalTrial
    handleDeleteTrial,
    futureTrialAgreement
  )

  const handleUpdateTrial = (trial: ClinicalTrial) => {
    setId(trial.id)
    setStudy(trial.study)
    setStartDate(trial.startDate)
    setEndDate(trial.endDate || '')
    setGroup(trial.group || '')
    onOpen()
  }

  const handleAddTrial = () => {
    setId(undefined)
    setStudy('')
    setStartDate('')
    setEndDate('')
    setGroup('')
    onOpen()
  }

  return (
    <>
      <Text mt={4} fontSize="lg" fontWeight="bold">
        Have you been in the past or are you currently in a clinical drug or
        treatment study?
      </Text>
      <Box mt={4}>
        <Button rightIcon={<AddIcon />} onClick={handleAddTrial}>
          Add trial
        </Button>
      </Box>
      <ClinicalTrialsTable
        clinicalTrials={additionalDetails.clinicalTrials || []}
        handleUpdateTrial={handleUpdateTrial}
        handleStopTrial={handleStopTrial}
        handleDeleteTrial={handleDeleteTrial}
      />
      <Checkbox
        mt={4}
        isChecked={futureTrialAgreement}
        onChange={(e) => setFutureTrialAgreement(e.target.checked)}
        isRequired
      >
        If in the future I join another ALS clinical trial I agree to add it
        here when I join.
      </Checkbox>
      <ClinicalTrialsModal
        isOpen={isOpen}
        onClose={onClose}
        id={id}
        study={study}
        setStudy={setStudy}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        group={group}
        setGroup={setGroup}
        handleSave={handleSave}
        extraInfoRequired={isRequired}
      />
      <StopClinicalTrialModal
        isOpen={isStopOpen}
        onClose={onStopClose}
        endDate={endDate}
        setEndDate={setEndDate}
        confirmStopTrial={confirmStopTrial}
      />
      <DeleteAlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
        onConfirm={confirmDeleteTrial}
      />
    </>
  )
}
