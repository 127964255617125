import { ArrowBackIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
// import moment from "moment";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { useLabs, useUserLabs } from "../data/labsCollection";
import CenteredSpinner from "../components/CenteredSpinner";
import useStore from "../store/useStore";

export default () => {
  const userId = useStore((s) => s.user?.uid);
  const [labs, foundLabs] = useLabs({ isArchived: false });
  const [userLabs] = useUserLabs({
    userId: userId,
    isArchived: true,
  });
  return (
    <Stack spacing={5}>
      <Heading>Your Archived Lab Tests</Heading>
      <Link to={"/labs/"}>
        <Button leftIcon={<ArrowBackIcon />} colorScheme="gray" size="md">
          View Labs
        </Button>
      </Link>
      {foundLabs !== true && <CenteredSpinner />}
      <SimpleGrid columns={2} columnGap={5}>
        {labs &&
          labs.map((lab, labIndex) => {
            return (
              <Box key={labIndex}>
                <Heading size={"md"} mt="5">
                  {lab.data().title} Lab Tests
                </Heading>
                <Box mt="5">
                  {userLabs &&
                    userLabs
                      .filter(
                        (userLab) => userLab.data().title === lab.data().title
                      )
                      .map((userLab, userLabIndex) => {
                        return (
                          <Card key={userLabIndex} mb="5">
                            <Link to={`/labs/edit/${userLab.id}`}>
                              <CardBody>
                                <p>
                                  <ViewIcon boxSize={6} mr="10" />
                                  {userLab
                                    .data()
                                    .date.toDate()
                                    .toLocaleDateString()}
                                </p>
                              </CardBody>
                            </Link>
                          </Card>
                        );
                      })}
                </Box>
              </Box>
            );
          })}
      </SimpleGrid>
    </Stack>
  );
};
