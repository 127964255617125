// PurpleBubbleContent.tsx
import React from "react";
import { Alert, AlertDescription } from "@chakra-ui/react";
import Markdown from "react-markdown";

interface PurpleBubbleContentProps {
  text: string;
}

const PurpleBubbleContent: React.FC<PurpleBubbleContentProps> = ({ text }) => {
  return (
    <Alert
      status="info"
      size="sm"
      rounded="md"
      backgroundColor="purple.100"
      variant="subtle"
    >
      <AlertDescription fontSize="sm">
        <Markdown>{text}</Markdown>
      </AlertDescription>
    </Alert>
  );
};

export default PurpleBubbleContent;
