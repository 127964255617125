import { DeleteIcon } from "@chakra-ui/icons";
import { Divider, HStack, Box, Button, Spacer } from "@chakra-ui/react";
import { UserLab } from "../../types/bloodWork";

export default ({
  onSubmitFunc,
  isLoading,
  isDisabled,
  submitButtonText,
  submitButtonColorScheme,
  cancelFunc,
  archiveFunc,
}: {
  onSubmitFunc?: (data: UserLab) => Promise<void> | undefined;
  isLoading: boolean;
  isDisabled: boolean;
  submitButtonText?: string;
  submitButtonColorScheme?: string;
  cancelFunc?: () => void | undefined;
  archiveFunc?: () => Promise<void> | undefined;
}) => {
  return (
    <>
      {onSubmitFunc && (
        <>
          <Divider />
          <HStack>
            <Box>
              <Button
                mt="5"
                type="submit"
                size="md"
                colorScheme={submitButtonColorScheme}
                isDisabled={isDisabled}
                isLoading={isLoading}
              >
                {submitButtonText}
              </Button>
            </Box>
            {cancelFunc && (
              <Box>
                <Button
                  onClick={cancelFunc}
                  mt="5"
                  colorScheme="gray"
                  size="md"
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                >
                  Cancel
                </Button>
              </Box>
            )}
            <Spacer />
            {archiveFunc && (
              <Box>
                <Button
                  rightIcon={<DeleteIcon />}
                  onClick={archiveFunc}
                  mt="5"
                  colorScheme="red"
                  size="md"
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                >
                  Archive
                </Button>
              </Box>
            )}
          </HStack>
        </>
      )}
    </>
  );
};
