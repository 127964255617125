// ModalComponent.tsx
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
} from "@chakra-ui/react";
import PurpleBubbleContent from "../../../components/PurpleBubbleContent";

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  status: "adding" | "editing" | "";
  title: string;
  text: string;
  setTitle: (title: string) => void;
  setText: (text: string) => void;
  handleSave: () => void;
  collectionName: string;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  isOpen,
  onClose,
  status,
  title,
  text,
  setTitle,
  setText,
  handleSave,
  collectionName,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {status === "adding"
            ? `Add new content for ${collectionName}`
            : `Edit content for ${title}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {status === "adding" && (
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              mb={4}
            />
          )}
          <Textarea
            placeholder="Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <p style={{ margin: "1em 0 0 0" }}>Preview</p>
          <hr style={{ margin: "0 0 1em 0" }} />
          <PurpleBubbleContent text={text} />
          <p>
            <a href="https://commonmark.org/help/">Markdown cheatsheet</a>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
