import { FormControl, FormLabel, Select } from '@chakra-ui/react'

interface AlsOnsetQuestionProps {
  value: string
  onChange: (value: string) => void
  isRequired: boolean
}

const AlsOnsetQuestion = ({
  value,
  onChange,
  isRequired,
}: AlsOnsetQuestionProps) => {
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>Type of ALS onset</FormLabel>
      <Select
        placeholder="Select option"
        defaultValue={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="bulbarOnset">Bulbar Onset</option>
        <option value="limbOnsetUpper">Limb Onset Upper</option>
        <option value="limbOnsetLower">Limb Onset Lower</option>
        <option value="bothUpperAndLower">Both Upper and Lower</option>
        <option value="bothBulbarAndLimbOnset">
          Both Bulbar and Limb Onset
        </option>
        <option value="atypical">Atypical</option>
      </Select>
    </FormControl>
  )
}

export default AlsOnsetQuestion
