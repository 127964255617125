import {
  Heading,
  Stack,
  Text,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  RequiredIndicator,
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import useCurrentReport from '../../hooks/useCurrentReport'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'
import { DairyStyleDiet } from '../../store/useStore'

const DairyQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()

  const handleRadioChange = (value: DairyStyleDiet) => {
    updateReport({ dairyStyleDiet: value })
  }

  return (
    <>
      <Heading size="md" mb={4}>
        Dairy Questions
      </Heading>
      <FormControl as="fieldset" isRequired>
        <FormLabel as="legend" requiredIndicator={<></>}>
          <Box display="flex" alignItems="center">
            <Text pl={5}>
              I consumed the following dairy products this month (choose one)
            </Text>
            <RequiredIndicator />
          </Box>
        </FormLabel>

        <RadioGroup
          onChange={handleRadioChange}
          value={report?.dairyStyleDiet}
          pl={5}
        >
          <Stack spacing={4}>
            <Radio value="gheeCreamOnly">Ghee and/or heavy cream only</Radio>
            <Radio value="gheeCreamButterOnly">
              Ghee, heavy cream and/or butter only
            </Radio>
            <Radio value="gheeCreamButterCheeseOnly">
              Ghee, heavy cream, butter and/or cheese only
            </Radio>
            <Radio value="dairy">
              <Box display="flex" alignItems="center">
                <Text>Dairy</Text>
                <Tooltip label="Includes cream, butter, milk, whey, kefir, buttermilk, yogurt, colostrum, and other milk-containing products">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="noDairyNoWhey">No dairy, no whey</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default DairyQuestions
