import { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Input,
} from '@chakra-ui/react'

const adultVaccines = [
  'Flu',
  'Shingles',
  'Covid19',
  'Pneumonia',
  'Meningitis A',
  'Meningitis B',
  'Hepatitis A',
  'Hepatitis B',
  'DPT',
  'Tetanus',
  'Malaria',
  'Dengue fever',
  'Other travel vaccines',
]

type VaccineData = {
  name: string
  hadVaccine: boolean
  firstVaccineAge: string
  recentVaccineAge: string
  estimatedTotal: string
}

export default function AdultVaccineHistory() {
  const [vaccineData, setVaccineData] = useState<VaccineData[]>(
    adultVaccines.map((vaccine) => ({
      name: vaccine,
      hadVaccine: false,
      firstVaccineAge: '',
      recentVaccineAge: '',
      estimatedTotal: '',
    }))
  )

  function handleCheckboxChange(index: number) {
    const newVaccineData = [...vaccineData]
    newVaccineData[index].hadVaccine = !newVaccineData[index].hadVaccine
    setVaccineData(newVaccineData)
  }

  function handleInputChange(
    index: number,
    field: 'firstVaccineAge' | 'recentVaccineAge' | 'estimatedTotal',
    value: string
  ) {
    const newVaccineData = [...vaccineData]
    newVaccineData[index][field] = value
    setVaccineData(newVaccineData)
  }

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Adult Vaccine History</Th>
          <Th>Had Vaccine</Th>
          <Th>Age ({'>'}18) of first vaccine</Th>
          <Th>Age of most recent vaccine</Th>
          <Th>Estimated total number of this vaccine</Th>
        </Tr>
      </Thead>
      <Tbody>
        {vaccineData.map((vaccine, index) => (
          <Tr key={vaccine.name}>
            <Td>{vaccine.name}</Td>
            <Td textAlign="left">
              <Checkbox
                isChecked={vaccine.hadVaccine}
                onChange={() => handleCheckboxChange(index)}
              />
            </Td>
            <Td>
              <Input
                value={vaccine.firstVaccineAge}
                onChange={(e) =>
                  handleInputChange(index, 'firstVaccineAge', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Age"
                type="number"
                min={18}
              />
            </Td>
            <Td>
              <Input
                value={vaccine.recentVaccineAge}
                onChange={(e) =>
                  handleInputChange(index, 'recentVaccineAge', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Age"
                type="number"
                min={18}
              />
            </Td>
            <Td>
              <Input
                value={vaccine.estimatedTotal}
                onChange={(e) =>
                  handleInputChange(index, 'estimatedTotal', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Number"
                type="number"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
