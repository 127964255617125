import React, { useEffect, useState } from "react";
import BasicDatePicker from "./BasicDatePicker";
import isRawDateValid from "../utils/isRawDateValid";

export default ({
  startYear,
  endYear,
  dateString,
  setDateString,
}: {
  startYear: string;
  endYear: string;
  dateString: string;
  setDateString: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theDate = new Date(dateString + "T00:00:00");
  const validDate = isRawDateValid(dateString);

  // console.log("String Date Picker dateString:", dateString);
  // console.log("String Date Picker theDate:", theDate);
  const theYear = validDate ? theDate.getFullYear().toString() : "0";
  let theMonth = "0";
  let theDay = "0";
  if (validDate) {
    const monthNum = theDate.getMonth() + 1;
    theMonth = monthNum > 9 ? monthNum.toString() : "0" + monthNum.toString();
    const dayNum = theDate?.getDate();
    theDay = dayNum > 9 ? dayNum.toString() : "0" + dayNum.toString();
  }
  // console.log(theYear, theMonth, theDay);

  const [year, setYear] = useState(theYear);
  const [month, setMonth] = useState(theMonth);
  const [day, setDay] = useState(theDay);

  useEffect(() => {
    setDateString(`${year}-${month}-${day}`);
    return () => {
      setDateString("");
    };
  }, [year, month, day, setDateString]);

  return (
    <BasicDatePicker
      startYear={startYear}
      endYear={endYear}
      year={year}
      setYear={setYear}
      month={month}
      setMonth={setMonth}
      day={day}
      setDay={setDay}
    />
  );
};
