import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { chain, map, orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import ConfigList from "../types/ConfigList";
import randomId from "../utils/randomId";

export default ({ docId, title }: { docId: string; title: string }) => {
  const [snap, setSnap] = useState<DocumentSnapshot<ConfigList>>();

  const ref = useMemo(() => {
    return doc(
      getFirestore(),
      "config",
      docId
    ) as DocumentReference<ConfigList>;
  }, [docId]);

  useEffect(() => {
    const unsub = onSnapshot(ref, setSnap);
    return () => unsub();
  }, [ref]);

  function update({ itemId, text }: { itemId: string; text: string }) {
    setDoc(
      ref,
      {
        value: {
          [itemId]: text,
        },
      },
      {
        merge: true,
      }
    );
  }

  function archive(itemId: string, archived: boolean) {
    const archivedIds = snap?.data()?.archivedIds || [];
    setDoc(
      ref,
      {
        archivedIds: archived
          ? archivedIds.concat(itemId)
          : archivedIds.filter((id) => id !== itemId),
      },
      { merge: true }
    );
  }

  const items = useMemo(() => {
    const data = snap?.data();
    if (!data) return [];

    const sortedItems = map(data.value, (text, itemId) => {
      return {
        text,
        itemId,
        archived: !!data.archivedIds?.includes(itemId),
      };
    });

    if (docId === "stressFactors") {
      return orderBy(sortedItems, (item) => item.text.toLowerCase(), "asc");
    }

    return orderBy(
      sortedItems,
      (item) => item.text.toLowerCase() + item.itemId,
      "asc"
    );
  }, [snap, docId]);

  return (
    <Stack spacing={2}>
      <Heading>{title}</Heading>
      <AddItem
        addItem={(text) => {
          const add = () => update({ itemId: randomId(), text });

          const compareWith = getCompareText(text);
          const similar = items.find(
            (i) => getCompareText(i.text) === compareWith
          );
          if (similar) {
            alert(
              `There is already a matching item in the list: ${similar.text}`
            );
          } else {
            add();
          }
        }}
      />
      {items.map(({ text, itemId, archived }) => {
        return (
          <Flex align="center">
            <Editable
              flex={1}
              key={itemId}
              defaultValue={text}
              onSubmit={(text) => {
                update({ itemId, text });
              }}
            >
              <EditablePreview />
              <EditableInput />
            </Editable>
            {archived ? (
              <IconButton
                aria-label="Unarchive"
                icon={<ViewIcon />}
                onClick={() => archive(itemId, false)}
              />
            ) : (
              <IconButton
                colorScheme="gray"
                aria-label="Archive"
                icon={<ViewOffIcon />}
                onClick={() => archive(itemId, true)}
              />
            )}
          </Flex>
        );
      })}
    </Stack>
  );
};

const AddItem = ({ addItem }: { addItem: (text: string) => void }) => {
  const [input, setInput] = useState("");

  function add() {
    addItem(input);
    setInput("");
  }

  return (
    <Input
      placeholder="Add Item"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          add();
        }
      }}
      value={input}
      onChange={(e) => setInput(e.target.value)}
    />
  );
};

function getCompareText(text: string) {
  return chain(text)
    .toLower()
    .split(" ")
    .join("")
    .split(".")
    .join("")
    .split("-")
    .join("")
    .value();
}
