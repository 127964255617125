import { Box, Button, Stack } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import FirestoreUser, { AdditionalDetails } from '../../types/FirestoreUser'
import SymptomQuestions from './SymptomsQuestions'
import ClinicalTrialsQuestions from './ClinicalTrials'
import useIsMine from '../../hooks/useIsMine'
import useSubmitAdditionalDetails from './useSubmitAdditionalDetails'
import useUpdateAdditionalDetails from './useUpdateAdditionalDetails'
import { handleSymptomChange } from './SymptomsQuestions/handleSymptomChange'
import AlsOnsetQuestion from './AlsOnsetQuestion'
import AlsFactorsQuestions from './AlsFactorsQuestions'
import useStopTrial from './ClinicalTrials/useStopTrial'

interface Props {
  uid: string
  firestoreUser: FirestoreUser
}

function EditAdditionalDetails({ uid, firestoreUser }: Props) {
  const isMine = useIsMine(uid)
  const { _additionalDetails } = useMemo(() => {
    return {
      _additionalDetails: firestoreUser?.additionalDetails,
    }
  }, [firestoreUser])
  const [additionalDetails, setAdditionalDetails] = useState<
    Partial<AdditionalDetails>
  >(_additionalDetails || { symptoms: [] })

  // extraInfoRequired is true if the user is a PALS. This is used to determine if fields should be required.
  const extraInfoRequired =
    !!firestoreUser.profile?.diagnosis &&
    !['medical', 'administrator'].includes(firestoreUser.profile.diagnosis)

  const update = useUpdateAdditionalDetails(setAdditionalDetails)

  useEffect(() => {
    if (_additionalDetails) {
      update(_additionalDetails)
    }
    // eslint-disable-next-line
  }, [_additionalDetails])

  const { submit, loading } = useSubmitAdditionalDetails(
    additionalDetails,
    isMine
  )

  const stopTrial = useStopTrial(uid, additionalDetails, setAdditionalDetails)

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
      >
        <Stack spacing={5}>
          <AlsOnsetQuestion
            value={additionalDetails.alsOnset || ''}
            onChange={(value) => update({ alsOnset: value })}
            isRequired={extraInfoRequired}
          />
          <AlsFactorsQuestions
            contributingFactors={additionalDetails.contributingFactors || {}}
            update={(factors) =>
              update({
                contributingFactors: {
                  ...additionalDetails.contributingFactors,
                  ...factors,
                },
              })
            }
            isRequired={extraInfoRequired}
          />
          <SymptomQuestions
            onChangeSymptom={(index, symptom) =>
              handleSymptomChange(index, symptom, additionalDetails, update)
            }
            additionalDetails={additionalDetails as AdditionalDetails}
            isRequired={extraInfoRequired}
          />
          <ClinicalTrialsQuestions
            additionalDetails={additionalDetails as AdditionalDetails}
            setAdditionalDetails={setAdditionalDetails}
            update={update}
            firestoreUser={firestoreUser}
            isRequired={extraInfoRequired}
            uid={uid}
            stopTrial={stopTrial}
          />
          <Box>
            <Button
              type="submit"
              disabled={loading}
              isLoading={loading}
              size="lg"
            >
              Save
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  )
}

export default EditAdditionalDetails
