import {
  Stack,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { map } from 'lodash'
import moment from 'moment'
import { Reports } from '../../store/useStore'

interface Props {
  reports: Reports
}

const carbDietMapping: { [key: string]: string } = {
  carnivore: 'Carnivore',
  carnivoreLion: 'Carnivore Lion',
  ketovore: 'Ketovore',
  ketogenic: 'Ketogenic',
  limitedCarb: 'Limited Carb',
  lowCarb: 'Low Carb',
  moderateCarb: 'Moderate Carb',
  unrestrictedCarb: 'Unrestricted Carb',
}

const dairyDietMapping: { [key: string]: string } = {
  gheeCreamOnly: 'Ghee and/or heavy cream only',
  gheeCreamButterOnly: 'Ghee, heavy cream and/or butter only',
  gheeCreamButterCheeseOnly: 'Ghee, heavy cream, butter and/or cheese only',
  dairy: 'Dairy',
  noDairyNoWhey: 'No dairy, no whey',
}

const grainDietMapping: { [key: string]: string } = {
  glutenFree: 'Gluten-Free',
  grainFreeCornFree: 'Grain-Free and Corn free',
  grainStrictlyLimited: 'Grain-strictly limited <= 2 cups grains per week',
  consumedGlutenGrains: 'I consumed Gluten/Grains this month',
}

export default function DietQuestions({ reports }: Props) {
  return (
    <Stack>
      <Heading fontSize="md">Diet Types</Heading>
      <TableContainer>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>Carb</Th>
              <Th>Dairy</Th>
              <Th>Grain</Th>
            </Tr>
          </Thead>
          <Tbody>
            {map(reports, (report, month) => {
              const m = moment(month + '-15')
              if (
                !report?.carbStyleDiet &&
                !report?.dairyStyleDiet &&
                !report?.grainStyleDiet
              )
                return null
              return (
                <Tr key={month}>
                  <Td>{m.format('MMM-YY')}</Td>
                  <Td>{carbDietMapping[report.carbStyleDiet!] || '-'}</Td>
                  <Td>{dairyDietMapping[report.dairyStyleDiet!] || '-'}</Td>
                  <Td>{grainDietMapping[report.grainStyleDiet!] || '-'}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
