import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
} from '@chakra-ui/react'

interface StopClinicalTrialModalProps {
  isOpen: boolean
  onClose: () => void
  endDate: string
  setEndDate: (date: string) => void
  confirmStopTrial: (date: string) => void
}

export default function StopClinicalTrialModal({
  isOpen,
  onClose,
  endDate,
  setEndDate,
  confirmStopTrial,
}: StopClinicalTrialModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Stop Clinical Trial</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>End Date (YYYY-MM-DD)</FormLabel>
            <Input
              placeholder="YYYY-MM-DD"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => confirmStopTrial(endDate)}
            ml={3}
            disabled={!endDate}
          >
            Stop
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
