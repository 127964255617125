import { useState, useRef } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { updateDoc, doc, getFirestore } from 'firebase/firestore'
import { AdditionalDetails } from '../../../types/FirestoreUser'

const useDeleteTrial = (
  uid: string,
  additionalDetails: Partial<AdditionalDetails>,
  setAdditionalDetails: React.Dispatch<
    React.SetStateAction<Partial<AdditionalDetails>>
  >
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<any>()
  const [trialToDelete, setTrialToDelete] = useState<string | null>(null)

  const handleDeleteTrial = (study: string) => {
    setTrialToDelete(study)
    onOpen()
  }

  const confirmDeleteTrial = async () => {
    if (trialToDelete) {
      const docRef = doc(getFirestore(), 'users', uid)

      try {
        const newArray = (additionalDetails.clinicalTrials || []).filter(
          (trial) => trial.id !== trialToDelete
        )

        await updateDoc(docRef, {
          'additionalDetails.clinicalTrials': newArray,
        })

        setAdditionalDetails((prevDetails) => ({
          ...prevDetails,
          clinicalTrials: newArray,
        }))

        setTrialToDelete(null)
        onClose()
      } catch (error) {
        console.error('Error deleting trial:', error)
      }
    }
  }

  return {
    isOpen,
    onOpen,
    onClose,
    cancelRef,
    handleDeleteTrial,
    confirmDeleteTrial,
  }
}

export default useDeleteTrial
