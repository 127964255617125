// src/components/MacroQuestions.tsx
import { Heading, Stack, Text, Grid, GridItem, Box } from '@chakra-ui/react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import useCurrentReport from '../../hooks/useCurrentReport'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'
import ValidatedInput from '../../components/ValidatedInput'
import usePurpleBubblesText from '../../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../../components/PurpleBubbleContent'

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, Title)

const MacroQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()
  const purpleBubbleContent = usePurpleBubblesText('Macro-Questions')

  const handleInputChange = (field: string, value: number | null) => {
    updateReport({ macros: { ...report?.macros, [field]: value } })
  }

  // Calculate calories from macros
  const proteinGrams = report?.macros?.protienGrams || 0
  const fatGrams = report?.macros?.fatGrams || 0
  const carbGrams = report?.macros?.carbGrams || 0

  const proteinCalories = proteinGrams * 4
  const fatCalories = fatGrams * 9
  const carbCalories = carbGrams * 4
  const totalCalories = proteinCalories + fatCalories + carbCalories

  // Chart configuration
  const chartData = {
    labels: ['Protein', 'Fat', 'Carbs'],
    datasets: [
      {
        data: [proteinCalories, fatCalories, carbCalories],
        backgroundColor: [
          '#48BB78', // Chakra green.500 (Protein)
          '#4299E1', // Chakra blue.500 (Fat)
          '#F56565', // Chakra red.500 (Carbs)
        ],
        borderWidth: 0,
      },
    ],
  }

  const chartOptions = {
    cutout: '70%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          boxWidth: 15,
          padding: 5,
          generateLabels: (chart: ChartJS) => {
            const data = chart.data
            if (data?.labels?.length && data.datasets.length) {
              return data.labels.map((label, i) => {
                const value = data.datasets[0].data[i] as number
                const percentage = ((value / totalCalories) * 100).toFixed(0)
                const backgroundColor = data.datasets[0].backgroundColor as
                  | string[]
                  | undefined
                return {
                  text: `${label} ${percentage}%`,
                  fillStyle: backgroundColor ? backgroundColor[i] : '#000',
                  hidden:
                    (isNaN(value ?? NaN) ||
                      (chart.getDatasetMeta(0).data[i] as any)?.hidden) ??
                    false,
                  index: i,
                }
              })
            }
            return []
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.parsed || 0
            const percentage = ((value / totalCalories) * 100).toFixed(0)
            return `${context.label} ${percentage}%`
          },
        },
      },
    },
  }

  return (
    <>
      <Box mb={4}>
        <Heading size="md" mb={2}>
          Macro Questions
        </Heading>
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <Text pl={5} mt={2}>
          We ask that you enter all food and beverages for ONE typical day
        </Text>
      </Box>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4}>
        {/* Left Column - Questions */}
        <GridItem>
          <Stack>
            <Text fontStyle="italic" pl={5}>
              On a typical day this month I ate:
            </Text>

            <ValidatedInput
              label="Grams of Protein"
              value={report?.macros?.protienGrams ?? null}
              min={1}
              max={300}
              isRequired={true}
              onChange={(value) => handleInputChange('protienGrams', value)}
              pl={5}
            />
            <ValidatedInput
              label="Grams of Fat"
              value={report?.macros?.fatGrams ?? null}
              min={1}
              max={300}
              isRequired={true}
              onChange={(value) => handleInputChange('fatGrams', value)}
              pl={5}
            />
            <ValidatedInput
              label="Grams of Net Carbohydrates"
              value={report?.macros?.carbGrams ?? null}
              min={0}
              max={300}
              isRequired={true}
              onChange={(value) => handleInputChange('carbGrams', value)}
              pl={5}
            />
          </Stack>
        </GridItem>

        {/* Right Column - Nutrition Summary */}
        <GridItem>
          <Box
            p={6}
            bg="white"
            borderRadius="xl"
            boxShadow="md"
            borderWidth="1px"
            borderColor="gray.100"
          >
            <Grid templateColumns="35% 65%" gap={8} mb={0}>
              {/* Left Column - Total Calories */}
              <GridItem>
                <Stack spacing={3} height="100%">
                  <Heading size="md" mb={1}>
                    Summary
                  </Heading>
                  <Box textAlign="left">
                    <Text fontWeight="bold">
                      Protein:{' '}
                      <Text as="span" fontWeight="normal">
                        {proteinCalories} kcal
                      </Text>
                    </Text>
                    <Text fontWeight="bold">
                      Fat:{' '}
                      <Text as="span" fontWeight="normal">
                        {fatCalories} kcal
                      </Text>
                    </Text>
                    <Text fontWeight="bold" mb={2}>
                      Carbs:{' '}
                      <Text as="span" fontWeight="normal">
                        {carbCalories} kcal
                      </Text>
                    </Text>
                    <Text fontWeight="bold">
                      Total:{' '}
                      <Text as="span" fontWeight="normal">
                        {totalCalories} kcal
                      </Text>
                    </Text>
                  </Box>
                </Stack>
              </GridItem>

              {/* Right Column - Doughnut Chart */}
              <GridItem>
                <Box height="150px">
                  <Doughnut data={chartData} options={chartOptions} />
                </Box>
              </GridItem>
            </Grid>
          </Box>
        </GridItem>
      </Grid>
    </>
  )
}

export default MacroQuestions
