import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLabs, useUserLabs } from "../data/labsCollection";
import CenteredSpinner from "../components/CenteredSpinner";
import useStore from "../store/useStore";

export default () => {
  const userId = useStore((s) => s.user?.uid);
  const [labs, foundLabs] = useLabs({ isArchived: false });
  const [userLabs] = useUserLabs({
    userId: userId,
    isArchived: false,
  });
  return (
    <Stack spacing={5}>
      <Heading>Your Lab Test Records</Heading>
      {foundLabs !== true && <CenteredSpinner />}
      <SimpleGrid columns={2} columnGap={5}>
        {labs &&
          labs.map((lab, labIndex) => {
            return (
              <Box key={labIndex}>
                <Link to={`/labs/add/${lab.data().title}`}>
                  <Button rightIcon={<AddIcon />}>
                    Add {lab.data().title} Lab Test
                  </Button>
                </Link>
                <Heading size={"md"} mt="5">
                  {lab.data().title} Lab Tests
                </Heading>
                <Divider />
                <Box mt="5">
                  {userLabs &&
                    userLabs
                      .filter(
                        (userLab) => userLab.data().title === lab.data().title
                      )
                      .map((userLab, userLabIndex) => {
                        return (
                          <Card key={userLabIndex} mb="5">
                            <Link to={`/labs/edit/${userLab.id}`}>
                              <CardBody>
                                <p>
                                  <EditIcon boxSize={6} mr="10" />
                                  {userLab
                                    .data()
                                    .date.toDate()
                                    .toLocaleDateString()}
                                </p>
                              </CardBody>
                            </Link>
                          </Card>
                        );
                      })}
                </Box>
              </Box>
            );
          })}
      </SimpleGrid>
      <Link to={"/labs/archived"}>
        <Button colorScheme="orange" rightIcon={<DeleteIcon />}>
          Archives
        </Button>
      </Link>
    </Stack>
  );
};
