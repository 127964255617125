import { Timestamp } from 'firebase/firestore'

export type Role = 'admin' | 'patient' | 'superadmin' | 'tester' | 'researcher'

export const roleTitles: Record<Role, string> = {
  admin: 'Admin',
  patient: 'User',
  superadmin: 'Super-Admin',
  tester: 'Tester',
  researcher: 'Researcher',
}

export const shortRoleTitles: Record<Role, string> = {
  admin: 'A',
  patient: 'U',
  superadmin: 'SA',
  tester: 'T',
  researcher: 'R',
}

export default interface FirestoreUser {
  email: string
  roles: Role[]
  createdAt: Timestamp
  unitSystem?: UnitSystem
  profile?: Profile
  additionalDetails?: AdditionalDetails
  medicalConditions?: MedicalCondition[]
  patientId?: string
  stages?: {
    [stageId: string]: StageStatus
  }
  bootCamps?: Partial<
    Record<
      number,
      {
        startedAt: {
          month: number
          year: number
        }
        completedAt: {
          month: number
          year: number
        } | null
      } | null
    >
  >
  essentialsCourses?: Partial<
    Record<
      number,
      {
        startedAt: {
          month: number
          year: number
        }
        completedAt: {
          month: number
          year: number
        } | null
      } | null
    >
  >
  lockedDate?: string
  studies?: string[]
}

export type StageStatus = 'unstarted' | 'started' | 'complete'

export type Profile = {
  firstName: string
  lastName: string
  searchFirstName: string
  searchLastName: string
  birthDate: string
  gender: Gender
  diagnosis: Diagnosis
  race: string
  education: string
  diagnosisDate: string
  diagnosisVerified: boolean
  diagnosisDocumentation?: string[]
  firstSymptomsDate: string
  height: string
  address: string
  city: string
  state: string
  country: string
  postalCode: string
  phoneNumber: string
  emergencyContactFirstName: string
  emergencyContactLastName: string
  emergencyContactEmail: string
  emergencyContactPhone: string
  agreedToProfileCheckbox: boolean
}

export type AdditionalDetails = {
  alsOnset: string
  contributingFactors: ContributingFactors
  symptoms: Symptom[]
  isInClinicalTrial: string
  clinicalTrials?: ClinicalTrial[]
  futureTrialAgreement: boolean
}

export type MedicalCondition = {
  conditionCategory:
    | 'various'
    | 'neurological'
    | 'heart'
    | 'gastro'
    | 'endocrine'
    | 'respiratory'
    | 'viral'
    | 'fungal'
    | 'parasites'
  conditionValue: string
  checked: boolean
  ageDiagnosed: number
  ageEnded: number | null
  intermittent: boolean
  estimated: number
}

export type Diagnosis =
  | 'als'
  | 'administrator'
  | 'medical'
  | 'als-probable'
  | 'pls'

export const diagnosisTitles: Record<Diagnosis, string> = {
  als: 'ALS',
  administrator: 'PALS Support Team Member',
  medical: 'Medical Professional or Researcher',
  'als-probable': 'Probable ALS',
  pls: 'PLS',
}
export const shortDiagnosisTitles: Record<Diagnosis, string> = {
  als: 'ALS',
  administrator: 'PSTM',
  medical: 'MP',
  'als-probable': 'Prob-ALS',
  pls: 'PLS',
}

export type Gender = 'male' | 'female' | 'other'

export type UnitSystem = 'metric' | 'imperial'

export type ContributingFactors = Partial<{
  primary: string | undefined
  secondary: string | undefined
  tertiary: string | undefined
  quaternary: string | undefined
  quinary: string | undefined
}>

export type Symptom = {
  area: string
  side: 'left' | 'right' | 'both' | undefined | ''
}

export type ClinicalTrial = {
  id: string
  study: string
  startDate: string
  endDate?: string
  group?: 'Interventional' | 'Placebo' | ''
}
