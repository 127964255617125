import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  IconButton,
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'

interface ClinicalTrial {
  id?: string
  study: string
  startDate: string
  endDate?: string
  group?: string
}

interface ClinicalTrialsTableProps {
  clinicalTrials: ClinicalTrial[]
  handleUpdateTrial: (trial: ClinicalTrial) => void
  handleStopTrial: (id: string) => void
  handleDeleteTrial: (id: string) => void
}

export default function ClinicalTrialsTable({
  clinicalTrials,
  handleUpdateTrial,
  handleStopTrial,
  handleDeleteTrial,
}: ClinicalTrialsTableProps) {
  return (
    <TableContainer mt={4}>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Actions</Th>
            <Th>Study</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th>Group</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clinicalTrials?.map((trial, index) => (
            <Tr key={index}>
              <Td>
                <Button
                  size="sm"
                  mr={1}
                  onClick={() => handleUpdateTrial(trial)}
                >
                  Update
                </Button>
                {trial.endDate === '' && (
                  <Button
                    size="sm"
                    mr={1}
                    colorScheme="red"
                    onClick={() => trial.id && handleStopTrial(trial.id)}
                  >
                    Stop
                  </Button>
                )}
                <IconButton
                  aria-label="Delete"
                  size="sm"
                  colorScheme="red"
                  variant="outline"
                  onClick={() => trial.id && handleDeleteTrial(trial.id)}
                  icon={<DeleteIcon />}
                />
              </Td>
              <Td>{trial.study}</Td>
              <Td>{trial.startDate}</Td>
              <Td>{trial.endDate || 'Current'}</Td>
              <Td>{trial.group || 'N/A'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
