// PurpleBubbleContent.tsx
import React from "react";
import { HStack, Select } from "@chakra-ui/react";

export default ({
  startYear,
  endYear,
  year,
  setYear,
  month,
  setMonth,
  day,
  setDay,
}: {
  startYear: string;
  endYear: string;
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  month: string;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  day: string;
  setDay: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const years = arrayRange(parseInt(startYear), parseInt(endYear), 1);
  const days = arrayRange(
    1,
    getDaysInMonth(parseInt(year), parseInt(month)),
    1
  );

  return (
    <HStack>
      <Select
        id="dp-year"
        maxWidth={90}
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value="year">Year</option>
        {years.reverse().map((year, index) => (
          <option key={index}>{year}</option>
        ))}
      </Select>
      <Select
        id="dp-month"
        maxWidth={130}
        value={month}
        onChange={(e) => {
          setMonth(e.target.value);
          setDay("");
        }}
      >
        {months.map((month, index) => (
          <option key={index} value={index > 9 ? index : "0" + index}>
            {month}
          </option>
        ))}
      </Select>
      <Select
        id="dp-day"
        maxWidth={90}
        value={day}
        onChange={(e) => setDay(e.target.value)}
      >
        <option value="day">Day</option>
        {days.map((day, index) => (
          <option key={index}>{day > 9 ? day : "0" + day}</option>
        ))}
      </Select>
    </HStack>
  );
};

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}
const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

const months = [
  "Month",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
