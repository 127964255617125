import {
  FormControl,
  FormLabel,
  Select,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { Symptom } from '../../../types/FirestoreUser'

const areasNotRequiringSide = [
  'Upper Back',
  'Lower Back',
  'Neck',
  'Speech',
  'Swallowing',
  'Throat',
  'Tongue',
]

const isSideRequired = (area: string) => !areasNotRequiringSide.includes(area)

const SymptomQuestion = ({
  index,
  symptom,
  rows,
  onChangeSymptom,
  extraInfoRequired,
}: {
  index: number
  symptom: Symptom
  rows: string[]
  onChangeSymptom: (index: number, symptom: Symptom) => void
  extraInfoRequired: boolean
}) => (
  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
    <GridItem>
      <FormControl isRequired={extraInfoRequired}>
        <FormLabel>Area</FormLabel>
        <Select
          placeholder="Select area"
          value={symptom?.area || ''}
          onChange={(e) => {
            const newArea = e.target.value
            onChangeSymptom(index, {
              ...symptom,
              area: newArea,
              side: isSideRequired(newArea) ? symptom.side : '',
            })
          }}
        >
          {rows.sort().map((row) => (
            <option key={row} value={row}>
              {row}
            </option>
          ))}
        </Select>
      </FormControl>
    </GridItem>
    <GridItem>
      <FormControl
        isRequired={extraInfoRequired && isSideRequired(symptom.area)}
      >
        <FormLabel>Side</FormLabel>
        <Select
          placeholder="Select side"
          value={symptom?.side || ''}
          onChange={(e) =>
            onChangeSymptom(index, {
              ...symptom,
              side: isSideRequired(symptom.area)
                ? (e.target.value as 'left' | 'right' | 'both')
                : '',
            })
          }
          isDisabled={!isSideRequired(symptom.area)}
        >
          {['left', 'right', 'both'].sort().map((side) => (
            <option key={side} value={side}>
              {side.charAt(0).toUpperCase() + side.slice(1)}
            </option>
          ))}
        </Select>
      </FormControl>
    </GridItem>
  </Grid>
)

export default SymptomQuestion
