import { Symptom, AdditionalDetails } from '../../../types/FirestoreUser'

export const handleSymptomChange = (
  index: number,
  symptom: Symptom,
  additionalDetails: Partial<AdditionalDetails>,
  update: (details: Partial<AdditionalDetails>) => void
) => {
  const newSymptoms = [...(additionalDetails.symptoms || [])]
  newSymptoms[index] = symptom
  update({
    symptoms: newSymptoms,
  })
}
