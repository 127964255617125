import {
  Box,
  Button,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react'
import FirestoreUser from '../../types/FirestoreUser'
import MedicalConditions from './MedicalConditions'
import usePurpleBubblesText from '../../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../../components/PurpleBubbleContent'
import InjuryHistory from './InjuryHistory '
import SurgeryHistory from './SurgeryHistory'
import PrescriptionHistory from './PrescriptionHistory'
import ChildhoodVaccineHistory from './ChildhoodVaccineHistory'
import AdultVaccineHistory from './AdultVaccineHistory'

interface Props {
  uid: string
  firestoreUser: FirestoreUser
}

function ProfileMedicalHistory({ uid, firestoreUser }: Props) {
  const purpleBubbleContent = usePurpleBubblesText('Profile-step-3')

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          // submit() ** Implement this function **
        }}
      >
        <Stack spacing={5}>
          {purpleBubbleContent?.text && (
            <PurpleBubbleContent text={purpleBubbleContent.text} />
          )}
          <Accordion defaultIndex={[]} allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Medical History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <MedicalConditions uid={uid} firestoreUser={firestoreUser} />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Injury History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <InjuryHistory />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Surgery History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <SurgeryHistory />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Prescription Drug History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <PrescriptionHistory />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Childhood Vaccine History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <ChildhoodVaccineHistory />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Adult Vaccine History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <AdultVaccineHistory />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box>
            <Button
              type="submit"
              // disabled={loading} ** Implement this function **
              // isLoading={loading}
              size="lg"
            >
              Save
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  )
}

export default ProfileMedicalHistory
