import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

interface Props {
  value: string
  label: string
  onChange: (value: string) => void
  isRequired: boolean
}

export default function AlsOnsetSelect({
  value,
  label,
  onChange,
  isRequired,
}: Props) {
  const [stressFactors, setStressFactors] = useState<Record<string, string>>({})

  useEffect(() => {
    const fetchStressFactors = async () => {
      const ref = doc(getFirestore(), 'config', 'stressFactors')
      const snap = await getDoc(ref)
      if (snap.exists()) {
        const data = snap.data()
        const { value, archivedIds } = data
        const filteredValue = Object.keys(value)
          .filter((key) => !archivedIds.includes(key))
          .reduce((acc, key) => {
            acc[key] = value[key]
            return acc
          }, {} as Record<string, string>)
        setStressFactors(filteredValue)
      }
    }
    fetchStressFactors()
  }, [])

  const sortedOptions = useMemo(() => {
    return Object.entries(stressFactors).sort(([, valueA], [, valueB]) => {
      const displayValueA = valueA.replace(/^\d+\.\s*/, '').toLowerCase()
      const displayValueB = valueB.replace(/^\d+\.\s*/, '').toLowerCase()
      return displayValueA.localeCompare(displayValueB)
    })
  }, [stressFactors])

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Select
        placeholder="Select option"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {sortedOptions.map(([key, optionValue]) => {
          const displayValue = optionValue.replace(/^\d+\.\s*/, '')
          return (
            <option key={key} value={key}>
              {displayValue}
            </option>
          )
        })}
      </Select>
    </FormControl>
  )
}
