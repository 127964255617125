import {
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Reports } from '../../store/useStore'
import { map } from 'lodash'
import moment from 'moment'

export default function MacrosIntake({ reports }: { reports: Reports }) {
  return (
    <Stack>
      <Heading fontSize="md">Macros Intake</Heading>
      <TableContainer>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>Grams Carbs</Th>
              <Th>Grams Fats</Th>
              <Th>Grams Protiens</Th>
            </Tr>
          </Thead>
          <Tbody>
            {map(reports, (report, month) => {
              const m = moment(month + '-15')
              if (!report?.macros) return null
              return (
                <Tr key={month}>
                  <Td>{m.format('MMM-YY')}</Td>
                  <Td>{report?.macros?.carbGrams || '-'}</Td>
                  <Td>{report?.macros?.fatGrams || '-'}</Td>
                  <Td>{report?.macros?.protienGrams || '-'}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
