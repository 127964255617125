import { Heading, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { labToUserLab, useLabs, userLabsStore } from "../data/labsCollection";
import CenteredSpinner from "../components/CenteredSpinner";
import NotFound from "./NotFound";
import useStore from "../store/useStore";
import UserLabTable from "../components/BloodWork/UserLabTable";
import { UserLab } from "../types/bloodWork";

export default () => {
  const { labTitle } = useParams();
  const navigate = useNavigate();

  const userId = useStore((s) => s.user?.uid);

  //Load Admin created lab to populate the UserLab
  const [labSnapshot, labFound] = useLabs({
    title: labTitle ?? "",
    isArchived: false,
  });

  let lab = null;

  if (labSnapshot && labSnapshot.length === 1) {
    lab = labSnapshot[0].data();
  }

  const userLab = lab ? labToUserLab(lab, userId ?? "") : null;

  const onSubmit = async (userLab: UserLab) => {
    userLab.archived = false;
    userLab.userId = userId ?? "";
    console.log(userLab);
    const createdLab = await userLabsStore.add(userLab);
    navigate(`/labs/edit/${createdLab.id}`);
  };

  if (labFound === false) {
    return <NotFound />;
  }

  if (!userLab) {
    return <CenteredSpinner />;
  }

  return (
    userLab && (
      <Stack spacing={5}>
        <Heading>Add {userLab.title} Lab Test</Heading>
        <UserLabTable
          userLab={userLab}
          onSubmitFunc={onSubmit}
          submitButtonText={"Save"}
          submitButtonColorScheme="purple"
          cancelFunc={() => navigate("/labs")}
        />
      </Stack>
    )
  );
};
