import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { RefObject } from 'react'

interface DeleteAlertDialogProps {
  isOpen: boolean
  leastDestructiveRef: RefObject<HTMLButtonElement>
  onClose: () => void
  onConfirm: () => void
}

export default function DeleteAlertDialog({
  isOpen,
  leastDestructiveRef,
  onClose,
  onConfirm,
}: DeleteAlertDialogProps) {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Clinical Trial
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this clinical trial? This action
            cannot be undone.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={leastDestructiveRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
