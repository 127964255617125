import { Text } from '@chakra-ui/react'
import { AdditionalDetails, Symptom } from '../../../types/FirestoreUser'
import SymptomQuestion from './SymptomQuestion'

interface Props {
  onChangeSymptom: (index: number, symptom: Symptom) => void
  additionalDetails: AdditionalDetails
  isRequired: boolean
}

export default function SymptomQuestions({
  onChangeSymptom,
  additionalDetails,
  isRequired,
}: Props) {
  const rows = [
    'Leg',
    'Foot',
    'Toe',
    'Shoulder',
    'Arm',
    'Hand',
    'Finger',
    'Neck',
    'Speech',
    'Swallowing',
    'Throat',
    'Tongue',
    'Upper Back',
    'Lower Back',
  ]

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" mt={4}>
        What area of your body were your FIRST symptoms?
      </Text>
      <SymptomQuestion
        index={0}
        symptom={additionalDetails.symptoms?.[0] || {}}
        rows={rows}
        onChangeSymptom={onChangeSymptom}
        extraInfoRequired={isRequired}
      />

      <Text fontSize="lg" fontWeight="bold" mt={4}>
        In what area were your SECOND symptoms?
      </Text>
      <SymptomQuestion
        index={1}
        symptom={additionalDetails.symptoms?.[1] || {}}
        rows={rows}
        onChangeSymptom={onChangeSymptom}
        extraInfoRequired={isRequired}
      />

      <Text fontSize="lg" fontWeight="bold" mt={4}>
        In what area were your THIRD symptoms?
      </Text>
      <SymptomQuestion
        index={2}
        symptom={additionalDetails.symptoms?.[2] || {}}
        rows={rows}
        onChangeSymptom={onChangeSymptom}
        extraInfoRequired={isRequired}
      />
    </>
  )
}
