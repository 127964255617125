import {
  Heading,
  Stack,
  Text,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  RequiredIndicator,
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import useCurrentReport from '../../hooks/useCurrentReport'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'
import { GrainStyleDiet } from '../../store/useStore'

const GrainQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()

  const handleRadioChange = (value: GrainStyleDiet) => {
    updateReport({ grainStyleDiet: value })
  }

  return (
    <>
      <Heading size="md" mb={4}>
        Grain Questions
      </Heading>
      <FormControl as="fieldset" isRequired>
        <FormLabel as="legend" requiredIndicator={<></>}>
          <Box display="flex" alignItems="center">
            <Text pl={5}>
              Choose one that best describes your consumption this month
            </Text>
            <RequiredIndicator />
            <Tooltip label="Gluten, yeast and grains have been linked to gut problems. Grains include wheat, rice, pasta and other grains. Corn, quinoa are also linked to gut problems. Seed oils especially are linked to a variety of health issues except the healthy oils: coconut oil, avocado oil and olive oil.">
              <QuestionIcon color="gray.400" />
            </Tooltip>
          </Box>
        </FormLabel>

        <RadioGroup
          onChange={handleRadioChange}
          value={report?.grainStyleDiet}
          pl={5}
        >
          <Stack spacing={4}>
            <Radio value="glutenFree">Gluten-Free</Radio>
            <Radio value="grainFreeCornFree">Grain-Free and Corn free</Radio>
            <Radio value="grainStrictlyLimited">
              Grain-strictly limited &lt;= 2 cups grains per week
            </Radio>
            <Radio value="consumedGlutenGrains">
              I consumed Gluten/Grains this month
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default GrainQuestions
