import { useState, useRef } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { updateDoc, doc, getFirestore } from 'firebase/firestore'
import { AdditionalDetails } from '../../../types/FirestoreUser'
import isRawDateValid from '../../../utils/isRawDateValid'

const useStopTrial = (
  uid: string,
  additionalDetails: Partial<AdditionalDetails>,
  setAdditionalDetails: React.Dispatch<
    React.SetStateAction<Partial<AdditionalDetails>>
  >
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<any>()
  const [trialToStop, setTrialToStop] = useState<string | null>(null)
  const toast = useToast()

  const handleStopTrial = (study: string) => {
    console.log('study', study)
    setTrialToStop(study)
    onOpen()
  }

  const confirmStopTrial = async (endDate: string) => {
    console.log(trialToStop)
    if (trialToStop) {
      const docRef = doc(getFirestore(), 'users', uid)

      try {
        // Find the trial to stop
        const trial = (additionalDetails.clinicalTrials || []).find(
          (trial) => trial.id === trialToStop
        )
        if (!trial) return

        const startDate = new Date(trial.startDate)

        if (!isRawDateValid(endDate)) {
          toast({
            title: 'Invalid Date',
            description: 'Please enter a valid date in YYYY-MM-DD format.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return
        }

        const endDateObj = new Date(endDate)
        if (endDateObj < startDate) {
          toast({
            title: 'Invalid End Date',
            description: 'End date cannot be before the start date.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return
        }

        // Update the trial with the new end date
        const newArray = (additionalDetails.clinicalTrials || []).map((trial) =>
          trial.id === trialToStop ? { ...trial, endDate } : trial
        )

        await updateDoc(docRef, {
          'additionalDetails.clinicalTrials': newArray,
        })

        setAdditionalDetails((prevDetails) => ({
          ...prevDetails,
          clinicalTrials: newArray,
        }))

        setTrialToStop(null)
        onClose()
      } catch (error) {
        console.error('Error stopping trial:', error)
      }
    }
  }

  return {
    isOpen,
    onOpen,
    onClose,
    cancelRef,
    handleStopTrial,
    confirmStopTrial,
  }
}

export default useStopTrial
