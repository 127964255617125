import { Button, IconButton, Stack, Td } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import {
  FrequencyUnit,
  AmountUnit,
  DurationUnit,
  Method,
} from '../../types/Regimen'

interface ActionButtonsProps {
  usageIndex: number
  record: any
  usage: any
  allowUpdate: boolean
  reportingForStart: string
  setEditingId: (id: string) => void
  setStartDate: (date: string) => void
  setFrequency: (frequency: string) => void
  setFrequencyUnit: (unit: FrequencyUnit) => void
  setAmount: (amount: string) => void
  setAmountUnit: (unit: AmountUnit) => void
  setMethod: (method: Method) => void
  setSelectedFields: (fields: any) => void
  setDuration: (duration: string) => void
  setDurationUnit: (unit: DurationUnit) => void
  setPercentage: (percentage: any) => void
  modal: any
  setConfirmEndId: (id: string) => void
  setConfirmDelete: (data: { recordId: string; usageIndex: number }) => void
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  usageIndex,
  record,
  usage,
  allowUpdate,
  reportingForStart,
  setEditingId,
  setStartDate,
  setFrequency,
  setFrequencyUnit,
  setAmount,
  setAmountUnit,
  setMethod,
  setSelectedFields,
  setDuration,
  setDurationUnit,
  setPercentage,
  modal,
  setConfirmEndId,
  setConfirmDelete,
}) => {
  const handleStop = () => {
    setConfirmEndId(record.id)
  }

  return (
    <Td>
      <Stack direction="row" spacing={1}>
        {usageIndex === 0 && !record.endDate && (
          <>
            {allowUpdate && (
              <Button
                size="sm"
                onClick={() => {
                  const usage = record.usages[record.usages.length - 1]
                  setEditingId(record.id)
                  setStartDate(usage.startDate)
                  setFrequency(usage.frequency?.toString() || '')
                  setFrequencyUnit(usage.frequencyUnit)
                  setAmount(usage.amount?.toString() || '')
                  setAmountUnit(usage.amountUnit)
                  setMethod(usage.method)
                  setSelectedFields(usage.fields)
                  setDuration(usage.duration?.toString() || '')
                  setDurationUnit(usage.durationUnit)
                  setPercentage(usage.percentage)

                  modal.onOpen()
                }}
              >
                Update
              </Button>
            )}
            <Button size="sm" colorScheme="red" onClick={handleStop}>
              Stop
            </Button>
          </>
        )}
        {usage.startDate >= reportingForStart && (
          <IconButton
            aria-label="Delete"
            ml={1}
            size="sm"
            colorScheme="red"
            variant="outline"
            onClick={() => {
              setConfirmDelete({
                recordId: record.id,
                usageIndex,
              })
            }}
            icon={<DeleteIcon />}
          />
        )}
      </Stack>
    </Td>
  )
}

export default ActionButtons
