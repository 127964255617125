import { useState } from 'react'
import { getAuth } from 'firebase/auth'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useToast } from '@chakra-ui/react'
import { AdditionalDetails } from '../../types/FirestoreUser'

const useSubmitAdditionalDetails = (
  additionalDetails: Partial<AdditionalDetails>,
  isMine: boolean
) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const submit = async () => {
    if (isMine) {
      const user = getAuth().currentUser
      if (!user) return alert('Not logged in!')

      setLoading(true)

      try {
        const userRef = doc(getFirestore(), 'users', user.uid)
        await updateDoc(userRef, {
          additionalDetails,
        })

        toast({
          status: 'success',
          title: 'Saved',
          description: 'Additional details were successfully saved!',
          isClosable: true,
        })
      } catch (e) {
        console.error(e)
        toast({
          status: 'error',
          title: 'Error',
          description: 'An error occurred while saving additional details.',
          isClosable: true,
        })
      } finally {
        setLoading(false)
      }
    }
  }

  return { submit, loading }
}

export default useSubmitAdditionalDetails
