import { doc, getFirestore, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import {
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import PrescriptionModal from './PrescriptionModal'

type PrescriptionData = {
  name: string
  ageStarted: number | null
  ageStopped: number | null
  usageType: 'Intermittent' | 'Constant'
  totalYears: number | null
}

export default function PrescriptionHistory() {
  const [prescriptionList, setPrescriptionList] = useState<
    Record<string, string>
  >({})
  const [prescriptionData, setPrescriptionData] = useState<PrescriptionData[]>(
    []
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [drug, setDrug] = useState('')
  const [category, setCategory] = useState('')
  const [ageStarted, setAgeStarted] = useState<number | null>(null)
  const [ageStopped, setAgeStopped] = useState<number | null>(null)
  const [usageType, setUsageType] = useState<'Intermittent' | 'Constant'>(
    'Intermittent'
  )
  const [totalYears, setTotalYears] = useState<number | null>(null)

  useEffect(() => {
    const fetchPrescriptionList = async () => {
      const ref = doc(getFirestore(), 'config', 'nonSupplementMedications')
      const snap = await getDoc(ref)
      if (snap.exists()) {
        const data = snap.data()
        const { value, archivedIds } = data
        const filteredValue = Object.keys(value)
          .filter((key) => !archivedIds.includes(key))
          .reduce((acc, key) => {
            acc[key] = value[key]
            return acc
          }, {} as Record<string, string>)
        setPrescriptionList(filteredValue)
      }
    }
    fetchPrescriptionList()
  }, [])

  const handleSavePrescription = () => {
    const prescriptionName = drug || category
    setPrescriptionData([
      ...prescriptionData,
      {
        name: prescriptionName,
        ageStarted,
        ageStopped,
        usageType,
        totalYears,
      },
    ])
  }

  const handleUpdatePrescription = (prescription: PrescriptionData) => {
    // Add your logic to handle updating a prescription here
  }

  const handleDeletePrescription = (name: string) => {
    // Add your logic to handle deleting a prescription here
  }

  return (
    <div>
      <Box mb={4}>
        <Button rightIcon={<AddIcon />} onClick={onOpen}>
          Add Prescription
        </Button>
      </Box>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Actions</Th>
            <Th>Prescription</Th>
            <Th>Age at which first took medication</Th>
            <Th>Age at which you stopped medication (or current)</Th>
            <Th>Intermittent or Constant</Th>
            <Th>Estimated Total Number of years</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prescriptionData.map((prescription, index) => (
            <Tr key={index}>
              <Td>
                <Flex>
                  <IconButton
                    aria-label="Update"
                    size="sm"
                    mr={1}
                    onClick={() => handleUpdatePrescription(prescription)}
                    icon={<EditIcon />}
                  />
                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    colorScheme="red"
                    variant="outline"
                    onClick={() => handleDeletePrescription(prescription.name)}
                    icon={<DeleteIcon />}
                  />
                </Flex>
              </Td>
              <Td>{prescription.name}</Td>
              <Td>{prescription.ageStarted} years old</Td>
              <Td>{prescription.ageStopped} years old</Td>
              <Td>{prescription.usageType}</Td>
              <Td>{prescription.totalYears} years</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <PrescriptionModal
        isOpen={isOpen}
        onClose={onClose}
        drug={drug}
        setDrug={setDrug}
        category={category}
        setCategory={setCategory}
        ageStarted={ageStarted}
        setAgeStarted={setAgeStarted}
        ageStopped={ageStopped}
        setAgeStopped={setAgeStopped}
        usageType={usageType}
        setUsageType={setUsageType}
        totalYears={totalYears}
        setTotalYears={setTotalYears}
        prescriptionList={prescriptionList}
        handleSave={handleSavePrescription}
      />
    </div>
  )
}
