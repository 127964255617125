import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import ClinicalTrialForm from './ClinicalTrialForm'

interface ClinicalTrialModalProps {
  id?: string
  isOpen: boolean
  onClose: () => void
  study: string
  setStudy: (study: string) => void
  startDate: string
  setStartDate: (date: string) => void
  endDate: string
  setEndDate: (date: string) => void
  group: string
  setGroup: (group: string) => void
  handleSave: () => void
  extraInfoRequired: boolean
}

function ClinicalTrialModal({
  id,
  isOpen,
  onClose,
  study,
  setStudy,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  group,
  setGroup,
  handleSave,
  extraInfoRequired,
}: ClinicalTrialModalProps) {
  const handleClose = () => {
    setStudy('')
    setStartDate('')
    setEndDate('')
    setGroup('')
    onClose()
  }

  const handleSaveAndClose = () => {
    handleSave()
    handleClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {id ? 'Update Clinical Trial' : 'Add Clinical Trial'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ClinicalTrialForm
            study={study}
            setStudy={setStudy}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            group={group}
            setGroup={setGroup}
            extraInfoRequired={extraInfoRequired}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveAndClose}>
            Save
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ClinicalTrialModal
