import { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Input,
} from '@chakra-ui/react'

const vaccines = [
  'DTP or DTaP Diptheria/Pertusses/Tetanus/',
  'Diptheria',
  'Tetanus',
  'Pertussis',
  'Polio (IPV or other polio)',
  'MMR Measles/Mumps/Rubella',
  'Measles',
  'Mumps',
  'Rubella',
  'Smallpox',
  'VAR Varicella or Chicken Pox',
  'Hepatitis A',
  'Hepatitis B',
  'Hib Haemophilus Influeza Type B',
  'RSV Rotavirus',
  'Meningacoccal Type B',
  'HPV Human Papilloma Virus',
  'PCV Pneumococcal conjugate',
  'Flu/Influenza',
  'Covid19',
  'Other',
]

type VaccineData = {
  name: string
  hadVaccine: boolean
  estimated: string
  booster1: string
  booster2: string
  booster3: string
}

export default function ChildhoodVaccineHistory() {
  const [vaccineData, setVaccineData] = useState<VaccineData[]>(
    vaccines.map((vaccine) => ({
      name: vaccine,
      hadVaccine: false,
      estimated: '',
      booster1: '',
      booster2: '',
      booster3: '',
    }))
  )

  function handleCheckboxChange(index: number) {
    const newVaccineData = [...vaccineData]
    newVaccineData[index].hadVaccine = !newVaccineData[index].hadVaccine
    setVaccineData(newVaccineData)
  }

  function handleInputChange(
    index: number,
    field: 'estimated' | 'booster1' | 'booster2' | 'booster3',
    value: string
  ) {
    const newVaccineData = [...vaccineData]
    newVaccineData[index][field] = value
    setVaccineData(newVaccineData)
  }

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Childhood Vaccine</Th>
          <Th>Had Vaccine</Th>
          <Th>Estimated # of vaccines, 5 years and under</Th>
          <Th>Age followup Booster 1</Th>
          <Th>Age followup Booster 2</Th>
          <Th>Age followup Booster 3</Th>
        </Tr>
      </Thead>
      <Tbody>
        {vaccineData.map((vaccine, index) => (
          <Tr key={vaccine.name}>
            <Td>{vaccine.name}</Td>
            <Td textAlign="left">
              <Checkbox
                isChecked={vaccine.hadVaccine}
                onChange={() => handleCheckboxChange(index)}
              />
            </Td>
            <Td>
              <Input
                value={vaccine.estimated}
                onChange={(e) =>
                  handleInputChange(index, 'estimated', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Number"
                type="number"
              />
            </Td>
            <Td>
              <Input
                value={vaccine.booster1}
                onChange={(e) =>
                  handleInputChange(index, 'booster1', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Age"
              />
            </Td>
            <Td>
              <Input
                value={vaccine.booster2}
                onChange={(e) =>
                  handleInputChange(index, 'booster2', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Age"
              />
            </Td>
            <Td>
              <Input
                value={vaccine.booster3}
                onChange={(e) =>
                  handleInputChange(index, 'booster3', e.target.value)
                }
                isDisabled={!vaccine.hadVaccine}
                placeholder="Age"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
