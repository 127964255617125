import { useToast } from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { doc, getFirestore, updateDoc, setDoc } from 'firebase/firestore'
import isRawDateValid from '../../../utils/isRawDateValid'
import FirestoreUser, {
  ClinicalTrial,
  AdditionalDetails,
} from '../../../types/FirestoreUser'
import { useCallback } from 'react'

const useSaveClinicalTrial = (
  id: string | undefined,
  study: string,
  startDate: string,
  endDate: string,
  group: string,
  firestoreUser: FirestoreUser,
  update: (partial: Partial<AdditionalDetails>) => void,
  onClose: () => void,
  uuidv4: () => string,
  additionalDetails: AdditionalDetails,
  toast: ReturnType<typeof useToast>,
  handleDeleteTrial: (study: string) => void,
  futureTrialAgreement: boolean
) => {
  const handleSave = useCallback(async () => {
    if (!study || !startDate) {
      return toast({
        status: 'error',
        title: 'Error',
        description: 'Study and Start Date are required fields.',
        isClosable: true,
      })
    }

    if (startDate && !isRawDateValid(startDate)) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'Start Date is in an incorrect format. Please enter in YYYY-MM-DD format.',
        isClosable: true,
      })
    }

    if (endDate && !isRawDateValid(endDate)) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'End Date is in an incorrect format. Please enter in YYYY-MM-DD format.',
        isClosable: true,
      })
    }

    const newTrial: ClinicalTrial = {
      id: id || uuidv4(), // Use existing ID or generate a new one
      study,
      startDate,
      endDate: endDate || '',
      group: group === 'Interventional' || group === 'Placebo' ? group : '',
    }

    const updatedTrials = id
      ? (firestoreUser.additionalDetails?.clinicalTrials || []).map((trial) =>
          trial.id === id ? newTrial : trial
        )
      : [...(firestoreUser.additionalDetails?.clinicalTrials || []), newTrial]

    try {
      const user = getAuth().currentUser
      if (!user) return alert('Not logged in!')

      const userRef = doc(getFirestore(), 'users', user.uid)
      await updateDoc(userRef, {
        'additionalDetails.clinicalTrials': updatedTrials,
        'additionalDetails.futureTrialAgreement': futureTrialAgreement,
      })

      update({ clinicalTrials: updatedTrials })
      await setDoc(
        doc(getFirestore(), `clinicalTrials/${newTrial.id}`),
        newTrial
      )

      toast({
        status: 'success',
        title: 'Saved',
        description: 'Clinical trial details were successfully saved!',
        isClosable: true,
      })
    } catch (e) {
      console.error(e)
      toast({
        status: 'error',
        title: 'Error',
        description: 'An error occurred while saving clinical trial details.',
        isClosable: true,
      })
    }

    onClose()
  }, [
    id,
    study,
    startDate,
    endDate,
    group,
    firestoreUser,
    update,
    onClose,
    uuidv4,
    toast,
    futureTrialAgreement,
  ])

  return { handleSave }
}

export default useSaveClinicalTrial
