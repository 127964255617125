import {
  CollectionReference,
  QueryDocumentSnapshot,
  collection,
  getFirestore,
  onSnapshot,
  doc,
  query,
  where,
  DocumentReference,
  addDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { Lab, UserLab } from "../types/bloodWork";

const userLabsPath = "user-labs";

const labsCollection = collection(
  getFirestore(),
  "labs"
) as CollectionReference<Lab>;

const userLabsCollection = collection(
  getFirestore(),
  userLabsPath
) as CollectionReference<UserLab>;

export const useLabs = ({
  title,
  isArchived,
}: {
  title?: string;
  isArchived?: boolean;
}): [QueryDocumentSnapshot<Lab>[] | undefined, boolean | undefined] => {
  const [labs, setLabs] = useState<QueryDocumentSnapshot<Lab>[]>([]);
  const [found, setFound] = useState<boolean | undefined>();

  useEffect(() => {
    const conditions = [];

    if (isArchived !== undefined) {
      conditions.push(where("archived", "==", isArchived));
    }
    if (title !== undefined) {
      conditions.push(where("title", "==", title));
    }

    const unsub = onSnapshot(
      query(labsCollection, ...conditions),
      (snap) => {
        if (snap.empty) {
          setFound(false);
        } else {
          setLabs(orderBy(snap.docs, (doc) => doc.data().title, "asc"));
          setFound(true);
        }
      },
      (error) => {
        console.error("Error retrieving document:", error);
        setFound(false);
      }
    );

    return () => unsub();
  }, [title, isArchived]);

  return [labs, found];
};

export const useUserLab = ({
  userId,
  userLabId,
  isArchived,
}: {
  userLabId: string;
  userId?: string;
  isArchived?: boolean;
}): [QueryDocumentSnapshot<UserLab> | undefined, boolean | undefined] => {
  const [userLab, setUserLab] = useState<QueryDocumentSnapshot<UserLab>>();
  const [found, setFound] = useState<boolean | undefined>();

  useEffect(() => {
    const docRef = doc(
      getFirestore(),
      userLabsPath,
      userLabId
    ) as DocumentReference<UserLab>;

    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          // Only check userId since it can't be part of the query
          if (userId && data.userId !== userId) {
            console.log("No document found for this user");
            setFound(false);
            setUserLab(undefined);
          } else if (isArchived !== undefined && data.archived !== isArchived) {
            console.log(
              `Document has wrong archive status: ${data.archived} !== ${isArchived}`
            );
            setFound(false);
            setUserLab(undefined);
          } else {
            setUserLab(docSnapshot);
            setFound(true);
          }
        } else {
          console.log("No document found with this ID", userLabId);
          setFound(false);
          setUserLab(undefined);
        }
      },
      (error) => {
        console.error("Error retrieving document:", error);
        setFound(false);
        setUserLab(undefined);
      }
    );

    return () => unsubscribe();
  }, [userLabId, isArchived, userId]);

  return [userLab, found];
};

export const useUserLabs = ({
  userId,
  title,
  isArchived,
}: {
  userId?: string;
  title?: string;
  isArchived?: boolean;
}): [QueryDocumentSnapshot<UserLab>[] | undefined, boolean | undefined] => {
  const [userLabs, setUserLabs] = useState<QueryDocumentSnapshot<UserLab>[]>(
    []
  );
  const [found, setFound] = useState<boolean | undefined>();

  useEffect(() => {
    const conditions = [];

    if (userId !== undefined) {
      conditions.push(where("userId", "==", userId));
    }
    if (title !== undefined) {
      conditions.push(where("title", "==", title));
    }
    if (isArchived !== undefined) {
      conditions.push(where("archived", "==", isArchived));
    }

    const unsub = onSnapshot(
      query(userLabsCollection, ...conditions),
      (snap) => {
        if (snap.empty) {
          setFound(false);
        } else {
          setUserLabs(orderBy(snap.docs, (doc) => doc.data().title, "asc"));
          setFound(true);
        }
      },
      (error) => {
        console.error("Error retrieving document:", error);
        setFound(false);
      }
    );

    return () => unsub();
  }, [title, isArchived, userId]);

  return [userLabs, found];
};

export const userLabsStore = {
  add(userLab: UserLab) {
    return addDoc(userLabsCollection, userLab);
  },
  update(userLabId: string, userLab: Partial<UserLab>) {
    const docRef = doc(
      getFirestore(),
      userLabsPath,
      userLabId
    ) as DocumentReference<UserLab>;

    return updateDoc(docRef, userLab);
  },
  archive(userLabId: string) {
    return this.update(userLabId, { archived: true });
  },
  unarchive(userLabId: string) {
    return this.update(userLabId, { archived: false });
  },
};

export const labToUserLab = (lab: Lab, userId: string): UserLab => {
  const userLab: UserLab = {
    userId: userId,
    title: lab.title,
    archived: false,
    date: Timestamp.fromDate(new Date()),
    panels: lab.panels.map((panel) => {
      return {
        title: panel.title,
        items: panel.items.map((item) => {
          return {
            title: item.title,
            unit: item.unit,
            referenceRange: item.referenceRange,
            optimalRange: item.optimalRange,
            result: "",
          };
        }),
      };
    }),
  };

  return userLab;
};

export default labsCollection;
