import {
  Heading,
  Stack,
  Text,
  Tooltip,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  RequiredIndicator,
  Box,
} from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import useCurrentReport from '../../hooks/useCurrentReport'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'
import { CarbStyleDiet } from '../../store/useStore'

const CarbDietQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()

  const handleRadioChange = (value: CarbStyleDiet) => {
    updateReport({ carbStyleDiet: value })
  }

  return (
    <>
      <Heading size="md" mb={4}>
        Carb Dieting Questions
      </Heading>
      <FormControl as="fieldset" isRequired>
        <FormLabel as="legend" requiredIndicator={<></>}>
          <Box display="flex" alignItems="center">
            <Text pl={5}>What best describes your diet for this month?</Text>
            <RequiredIndicator />
            <Tooltip label="Net Carbohydrates = Total grams Carbohydrates minus grams fiber. To calculate: Carb Manager App, My Fitness Pal, Cronometer, or other free app.">
              <QuestionIcon color="gray.400" />
            </Tooltip>
          </Box>
        </FormLabel>

        <RadioGroup
          onChange={handleRadioChange}
          value={report?.carbStyleDiet}
          pl={5}
        >
          <Stack spacing={4}>
            <Radio value="carnivore">
              <Box display="flex" alignItems="center">
                Carnivore
                <Tooltip label="0 carbohydrates includes Beef, Butter, Bacon, Eggs, Fish, Chicken, Pork, Wild game">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="carnivoreLion">
              <Box display="flex" alignItems="center">
                Carnivore Lion
                <Tooltip label="0 carbohydrates - Lion diet includes Beef, Lamb, Wild game, No dairy">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="ketovore">
              <Box display="flex" alignItems="center">
                Ketovore
                <Tooltip label="&lt; 10 grams net carbohydrates per day, can include cream, butter, cheese, coconut oil, avocado, pickles, sauerkraut olives, sometimes a bit of fruit">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="ketogenic">
              <Box display="flex" alignItems="center">
                Ketogenic
                <Tooltip label="11 to 25 grams net carbohydrates per day">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="limitedCarb">
              <Box display="flex" alignItems="center">
                Limited Carbohydrate
                <Tooltip label="26 to 50 grams net carbohydrates per day">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="lowCarb">
              <Box display="flex" alignItems="center">
                Low Carbohydrate
                <Tooltip label="51 to 75 grams carbohydrates per day">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="moderateCarb">
              <Box display="flex" alignItems="center">
                Moderate Carbohydrate
                <Tooltip label="76 to 150 grams carbohydrates per day">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
            <Radio value="unrestrictedCarb">
              <Box display="flex" alignItems="center">
                Unrestricted Carbohydrate
                <Tooltip label="&gt; 150 grams carbohydrates per day">
                  <QuestionIcon color="gray.400" ml={2} />
                </Tooltip>
              </Box>
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default CarbDietQuestions
