import { range } from 'lodash'

export type StoolQuestionnaire = {
  stool: {
    type: number
    color: string
    odor: string
    daily: boolean
    times: number
    symptoms: string[]
    float: boolean | string
    volume: number
    giSymptoms: string[]
    giSymptomsSeverity?: { giSymptom: string; severity: string }[]
    complete?: boolean
  }
  urine: {
    timesDaily: number
    color: number
    odor: string
    complete?: boolean
  }
}

export const stoolTypes = range(1, 8).map((value) => ({
  value,
  text: `Type ${value}`,
}))

export const stoolTypes2: Record<number, string> = {
  1: 'Separate hard lumps',
  2: 'Lumpy, sausage-like',
  3: 'Sausage-shaped, w/ cracks',
  4: 'Smooth, soft sausage',
  5: 'Soft blobs, clear-cut edges',
  6: 'Mushy w/ ragged edges',
  7: 'Liquid, no solids',
}

export const stoolColors = [
  'brown',
  'tan',
  'yellow',
  'black',
  'green',
  'black-brown',
  'green-brown',
  'yellow-brown',
]

export const stoolVolumes: Record<number, string> = {
  0: 'Very Low',
  1: 'Low',
  2: 'Medium',
  3: 'Large',
  4: 'Very Large',
}

export const odors = ['mild', 'medium', 'strong']

export const urineColors: Record<number, string> = {
  1: 'Clear',
  2: 'Light Yellow',
  3: 'Yellow',
  4: 'Dark Yellow',
  5: 'Orange',
}

export const giSymptoms: Record<string, string> = {
  abdominalBloating: 'Abdominal bloating',
  abdominalCramping: 'Abdominal cramping',
  abdominalPain: 'Abdominal pain',
  analBurning: 'Anal burning',
  analItching: 'Anal itching',
  badBreath: 'Bad breath',
  belching: 'Belching',
  constipation: 'Constipation',
  diarrhea: 'Diarrhea',
  fecalLeakage: 'Anal leakage',
  heartburn: 'Heartburn',
  hemorrhoids: 'Hemorrhoids',
  gas: 'Gas',
  vomiting: 'Vomiting',
  bloodInStool: 'Blood in stool',
  mucusInStool: 'Mucus in stool',
  foodParticlesInStool: 'Food particles in stool',
  bloodInUrine: 'Blood in urine',
}
